import "./award.css";

const AwardImage = ({ image, icon, title, subtitle }) => {
  return (
    <div className="award-image-container">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <div className="award-receice-icon">
          <img src={image} alt="awards" className="image-awards" />
        </div>
        <div className="award-receive-title">{title}</div>
        <div className="award-receive-subtitle">{subtitle}</div>
      </div>
    </div>
  );
};

export default AwardImage;
