import "./index.css";
import { useEffect, useRef, useState } from "react";
import PageSection from "../global/PageSection";
import styled from "styled-components";
import Slider from "react-slick";
import { APIBASE } from "../../apiCondig";
import { otherProjectData } from "../../api/golednProperty";
import { useParams } from "react-router-dom";
import { ProjectCard } from "../../components/OurProjects/index";
import residentialData from "./data";

const Projects = ({ projectType }) => {
  const sliderRef = useRef();
  const [projects, setProjects] = useState([]);
  const [type, setType] = useState("residential");
  const params = useParams();
  var paramid = params.id;

  useEffect(() => {
    setType(projectType);

    otherProjectData(type, paramid).then((res, rej) => {
      if (res.status === 200) {
        const data = type!=="residential"?(res?.data?.properties):residentialData;
        const filteredData = data.filter(item => item._id !== paramid)
        setProjects(filteredData);
      } else {
        console.log(rej, "this is rej");
      }
    });
  }, [projectType, type, paramid]);
  let settings = {
    autoplay: true,
    autoplaySpeed: 3000,
    // dots: true,
    arrows: false,
    speed: 3500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    infinite: true,
    responsive: [
      {
        breakpoint: 1392,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1060,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 745,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  const OurProjectsContainer = styled.div`
    display: flex;
    margin: 30px;
    margin-bottom: 0;
    padding-bottom: 30px;
    @media (max-width: 768px) {
      margin-left: 0;
      margin-right: 0;
    }
  `;
  useEffect(() => { }, []);
  return (
    <PageSection
      title={"Other Projects"}
      subTitle={"Based on preferences of users like you"}
      titleTop={50}
      titleSubTitleSpace={22}
      titleBottom={31}
      style={{
        minHeight: 638,
        background: "rgb(248, 255, 249)",
      }}
    >
      <div
        style={{ display: "flex", justifyContent: "center" }}
        className="projects-filter-container"
      ></div>
      <OurProjectsContainer>
        <div
          style={{ width: 5 }}
          className="other_projects-slider-action-holder"
        >
          <div
            className="other_projects-carousal-left-chevron-btn"
            onClick={() => {
              sliderRef.current.slickPrev();
            }}
          >
            <svg
              width="10"
              height="19"
              viewBox="0 0 10 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.9375 1.47925L0.916667 9.50008L8.9375 17.5209"
                stroke="#5D5F5F"
                strokeWidth="1.8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>

        <div style={{ flex: 1, width: 50 }}>
          <div style={{}}>
            <Slider {...settings} ref={sliderRef}>
              {projects.map((item, i) => {
                if (item?.title.toUpperCase() === "GOLDENPROPERTY") {
                  item.logo = "/assets/ProjectUnderLogo/GOLDENPROPERTY.png";
                }
                return (
                  <>
                    <div className="other-project__card">
                      <ProjectCard
                        image={item?.title.toUpperCase() === "GOLDENPROPERTY"?"/assets/ProjectUnderLogo/residential/golden-prop.png":item.image[0]}
                        logo={item?.type === "residential" ? "/assets/ProjectUnderLogo/GOLDENPROPERTY.png" : item.logo}
                        //logo={item.logo}
                        title={item?.title}
                        description={item?.description}
                        link={item?.title.toUpperCase() === "GOLDENPROPERTY" ? "/project/63a1b47972f212666ad7547e" : `/project/${item?._id}`}
                        width="100%"
                      />
                    </div>
                  </>
                );
              })}
            </Slider>
          </div>
        </div>

        <div
          style={{ width: 5 }}
          className="other_projects-slider-action-holder"
        >
          <div
            className="other_projects-carousal-right-chevron-btn"
            onClick={() => {
              sliderRef.current.slickNext();
            }}
          >
            <svg
              width="10"
              height="19"
              viewBox="0 0 10 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.0625 17.5208L9.08333 9.49992L1.0625 1.47908"
                stroke="#5D5F5F"
                strokeWidth="1.8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </OurProjectsContainer>
      <div style={{}} className="other_projects-slider-small-action-holder">
        <div
          className="other_projects-carousal-left-chevron-btn-responsive"
          style={{ marginTop: 0, left: 0 }}
          onClick={() => {
            sliderRef.current.slickPrev();
          }}
        >
          <svg
            width="10"
            height="19"
            viewBox="0 0 10 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.9375 1.47925L0.916667 9.50008L8.9375 17.5209"
              stroke="#5D5F5F"
              strokeWidth="1.8"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div style={{ width: 18 }}></div>
        <div
          className="other_projects-carousal-right-chevron-btn-responsive"
          style={{ marginTop: 0, left: 0 }}
          onClick={() => {
            sliderRef.current.slickNext();
          }}
        >
          <svg
            width="10"
            height="19"
            viewBox="0 0 10 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.0625 17.5208L9.08333 9.49992L1.0625 1.47908"
              stroke="#5D5F5F"
              strokeWidth="1.8"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </PageSection>
  );
};

function loadprojects(cb) {
  var myHeaders = new Headers();
  myHeaders.append(
    "Cookie",
    "sails.sid=s%3AVMjWRXtLoViz1nDUyIXJhuFsyJAlPltr.pXO57A0bsp%2FQMBh6x22Ze5rrVm2tGzixJwqUy1q2nAc"
  );

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  fetch(APIBASE + "/Properties", requestOptions)
    .then((response) => response.text())
    .then((result) => cb(result))
    .catch((error) => console.log("error", error));
}
export default Projects;
