import React, { useEffect } from "react";
import styled from "styled-components";
import SocialButton from "../NavBar/SocialButton";
import { gapi } from "gapi-script";
import { message } from "antd";

const Title = styled.div`
  font-weight: 500;
  font-size: 29px;
  line-height: 22px;

  color: #000000;
`;
const SubTitle = styled.div`
  font-weight: 400;
  font-size: 17px;
  line-height: 18px;

  color: rgba(0, 0, 0, 0.6);
`;
const TitleAndSubTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const LoginModal = ({ handleModal }) => {
  const clientId =
    "623670312524-iibj1sv8esu8gh7mhvovkrkg64kfefps.apps.googleusercontent.com";

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        appId: clientId,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  });
  const onSuccess = (res) => {
    message.success("LoggedIn Successfully");
    localStorage.setItem("access_token", res._token.idToken);
    localStorage.setItem("name", res._profile.name);
    localStorage.setItem("email", res._profile.email);
    localStorage.setItem("profilePic", res._profile.profilePicURL);
    handleModal();
  };

  function onFailure(err) {
    console.log("error", err);
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        textAlign: "center",
        gap: "32px",
        padding: "20px 30px",
      }}>
      <TitleAndSubTitle>
        <Title>Welcome</Title>
        <SubTitle>Continue with google to like, dislike and comment.</SubTitle>
      </TitleAndSubTitle>

      <SocialButton
        provider='google'
        prompt='select_account'
        appId={clientId}
        onLoginSuccess={onSuccess}
        onLoginFailure={onFailure}
      />
    </div>
  );
};

export default LoginModal;
