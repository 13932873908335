import React, { useState, useEffect } from 'react';
import popupStyle from "./css/index.module.css";
import ContactForm from "../ConnectForm/ConnectForm";
import { useParams } from "react-router-dom";


export default function ConnectWithUsPopup({duration, infinite=false}) {
    const [isHidden, setHidden] = useState(true);
    const [initialRender, setInitialRender] = useState(true);
    const popupHandler = ()=>{setHidden(true)}

    function makeVisible(durationVal) {
        setTimeout(() => {
          setHidden(false);
          setInitialRender(false);
        }, durationVal);
    }
    useEffect(() => {
        if (infinite && isHidden){ 
          if (initialRender) {
            makeVisible(15000);
          } else {
            makeVisible(60000);
          }
        }
    }, [isHidden])

    useEffect(() => {
      if(!infinite && initialRender){
        setTimeout(() => {
          setHidden(false);
        }, 15000);
      }
      setInitialRender(true)
    }, [])
    

  return (
    <>
    {!isHidden && (<div className={popupStyle.container}>
      <div className={popupStyle.darkBg} onClick={popupHandler}></div>
        <div className={popupStyle.wrapper} style={{backgroundImage: "url('/assets/ConnectWithUsPopup/popup_image.png')"}}>
            <img src="/assets/ConnectWithUsPopup/cross.svg" className={popupStyle.cross_btn} onClick={popupHandler} alt=""/>
            <div className={popupStyle.form_container}>
                <h3 className={popupStyle.form_title}>Connect with us</h3>
                <ContactForm buttonTop={12} onSuccess={()=>setHidden(true)}/>
            </div>
        </div>
    </div>)}
    </>
  )
}
