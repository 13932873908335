import React, {useState} from "react";
import { BlogItem } from "./BlogCard";
import Comments from "./Comments/index";

export default function BlogWithComment({
  eachValue,
  token,
  name,
  email,
  index,
  handleShare,
  handleLike,
  handleDisLike,
  setLoginModal
}) {
  const [isCommentVissble, setCommentVissble] = useState(false);
  const handleToggleComment = () => {
    if (token && email) {
      setCommentVissble((p) => !p);
    } else {
      setLoginModal(true);
    }
  };
  return (
    <>
      <BlogItem
        eachValue={eachValue}
        token={token}
        name={name}
        email={email}
        index={index}
        handleShare={handleShare}
        handleLike={handleLike}
        handleDisLike={handleDisLike}
        toggleComment={handleToggleComment}
      />
      <Comments
        blogId={eachValue._id}
        toggleComment={handleToggleComment}
        isVissble={isCommentVissble}
      />
    </>
  );
}
