import React, { useState } from "react";
import styled from "styled-components";
import { Input, Form, message } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { contactUS } from "../../api/golednProperty/index";
import OrImage from "./images/OrImage.svg";
import WhatsAppIcon from "./images/WhatsAppIcon.svg";

const { TextArea } = Input;

const ContactFormMainDiv = styled.div`
  padding: 0%;
  text-align: left;
`;
const SendButtonDiv = styled.div`
  text-align: center;
  justify-content: center;
`;

const InputDiv = styled.div`
  width: 100%;
  margin-bottom: 0em;
`;
const SendButton = styled.button`
  width: 100%;
  height: 40px;

  /* Primary-Color */
  outline: none;
  border: none;
  background: #209541;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.02em;
  text-transform: uppercase;

  color: #ffffff;
`;
export default function ContactForm({ showLetTalk = true, buttonTop = 10, onSuccess }) {
  const [phone, setPhone] = useState("");
  const [form] = Form.useForm();

  const handlePhoneInput = (value, country, e, formattedValue) => {
    setPhone(value);
  };

  const submitHandler = (e) => {
    const submittedValue = {
      name: e.name,
      email: e.email,
      phone: e.phonenumber,
      message: e?.message,
    };

    contactUS(submittedValue)
      .then((response) => {
        if (response.status === 200) {
          message.success("Thanks for contacting us. Will get back shortly!!");
          form.resetFields();
          onSuccess()
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <ContactFormMainDiv>
      <Form onFinish={submitHandler} name="basic" form={form} layout="vertical">
        <InputDiv>
          <Form.Item
            name="name"
            label="Full Name"
            required={false}
            rules={[
              {
                required: true,
                message: "Please enter your name",
              },
              {
                pattern: new RegExp(/^[a-z \s ]+$/i),
                message: "Invalid name",
              },
            ]}
            style={{ marginBottom: "10px" }}
          >
            <Input
              auto-size
              placeholder="Name"
              style={{ width: "100%", height: "38px" }}
            />
          </Form.Item>
        </InputDiv>
        <InputDiv>
          <Form.Item
            name="phonenumber"
            required={false}
            label="Phone Number"
            rules={[
              {
                required: true,
                message: "Please input your phone number!",
              },
              {
                min: 12,
                message: "Invalid Phone Number",
              },
            ]}
            style={{ marginBottom: "10px" }}
          >
            <PhoneInput
              country={"in"}
              value={phone}
              countryCodeEditable={false}
              placeholder="Mobile number"
              specialLabel="Phone Number"
              inputStyle={{
                width: "100%",
                height: "38px",
              }}
              onChange={handlePhoneInput}
            />
          </Form.Item>
        </InputDiv>
        <InputDiv>
          <Form.Item
            name="email"
            labelAlign=""
            label="Email"
            required={false}
            rules={[
              {
                required: true,
                message: "Enter your Email",
              },
            ]}
            style={{ marginBottom: "10px" }}
          >
            <Input
              type="email"
              placeholder="Eg. Sushma.singh@gmail.com"
              style={{ width: "100%", height: "38px" }}
            />
          </Form.Item>
        </InputDiv>
        <SendButtonDiv style={{ marginTop: buttonTop }}>
          <SendButton type="primary" htmlType="submit">
            CONNECT
          </SendButton>
        </SendButtonDiv>
      </Form>
      {showLetTalk && (
        <div style={{ width: "100%", marginBottom: 10 }}>
          <div style={{ width: "100%", margin: "6px 0px" }}>
            <img src={OrImage} alt="whatsapp icon" width={"100%"} />
          </div>

          <a
            aria-label="Chat on WhatsApp"
            href={"https://wa.me/7349770316"}
            target="_blank"
            alt="" rel="noreferrer"
          >
            <img src={WhatsAppIcon} alt="whatsapp icon" width={"100%"} />
          </a>
        </div>
      )}
    </ContactFormMainDiv>
  );
}
