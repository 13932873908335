import "./index.css";
import PageSectionHeader from "../PageSectionHeader";
const PageSection = ({
  children,
  title,
  subTitle,
  noHeader = false,
  bgImage,
  style,
  titleTop = 86,
  titleBottom = 86,
  width = 1440,
  titleSubTitleSpace
}) => {
  return (
    <div
      className="page-section"
      style={{
        backgroundImage: bgImage ? `url("${bgImage}")` : "none",
        ...style,
      }}
    >
      <div style={{ maxWidth: width,  margin: "auto"}}>
        {!noHeader && (
          <PageSectionHeader
            title={title}
            subTitle={subTitle}
            titleTop={titleTop}
            titleSubTitleSpace={titleSubTitleSpace}
            titleBottom={titleBottom}
          />
        )}
        {children}
      </div>
    </div>
  );
};

export default PageSection;
