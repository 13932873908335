const Tab = ({ images, selectedTab = "About", setselectedTab }) => {
  const scrollToElement = (id, block) => {
    var element = document.getElementById(id);
    element.scrollIntoView({ block: block || "center", behavior: "smooth" });
  };
  return (
    <div
      style={{
        // minHeight: 65,
        margin: 10,
        marginTop: 19,
        border: "1px solid #ccc",
        borderRadius: 5,
        background: "#fff",
        padding: "25px 0px",
        display: "flex",
        // alignItems: "center",
        justifyContent: "center",
        marginBottom: 0,
        paddingBottom: 0,
      }}
    >
      {["About", "Highlights", "Amenities", "Brochure", "Locality"].map(
        (d, i) => (
          <a
            key={"tab" + i}
            className="tab-container"
            onClick={(e) => {
              switch (d) {
                case "About":
                  scrollToElement("ProjectDetailsTabAbout");
                  break;
                case "Highlights":
                  scrollToElement("ProjectDetailsTabUnits");
                  break;
                case "Amenities":
                  scrollToElement("ProjectDetailsTabAmenities");
                  break;
                case "Brochure":
                  scrollToElement("ProjectDetailsTabBroucher");
                  break;
                default:
                  scrollToElement("ProjectDetailsTabNearThings", "start");
              }
            }}
          >
            <div
              style={{
                borderBottom: selectedTab === d ? "4px solid #209541" : "",
                color: selectedTab === d ? " #209541" : "#212325",
                paddingBottom: selectedTab === d ? 21 : 25,
              }}
            >
              {d}
            </div>
          </a>
        )
      )}
    </div>
  );
};

export default Tab;
