import "./index.css";
import { useEffect, useRef, useState } from "react";
import PageSection from "../global/PageSection";
import Slider from "react-slick";
import { APIBASE } from "../../apiCondig";
import { testimonials } from "../../api/golednProperty";
import mime from "mime";
import styled from "styled-components";
import { Modal } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import PlayButton from "../../assets/images/Blogs/PlayButton.svg";
const VideoPreview = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  max-height: 100vh;
  align-items: center;
`;
const StyledVideo = styled.video`
  width: 80%;
  height: 80%;
  @media (max-width: 700px) {
    width: 100%;
    height: 300px;
  }
`;
const PositionedCloseIcon = styled(CloseCircleOutlined)`
  position: absolute;
  top: 10px;
  right: 5px;
  color: white;
  font-size: 30px;
`;
const PlayButtonDiv = styled.div`
  position: absolute;
  z-index: 10;
  top: 130px;
  left: 100px;
  @media (max-width: 778px) {
    top: 80px;
    left: 150px;
  }
`;
const ReadMoreDiv = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #209541;
  margin-left: 1.5%;
  margin-top: 5px;
  @media (max-width: 778px) {
    margin-top: 0px;
  }
`;
const Projects = ({}) => {
  const sliderRef = useRef();
  const [projects, setProjects] = useState([]);
  function detectFileType(file) {
    const fileMime = mime.getType(file);

    if (fileMime?.startsWith("image/")) {
      return "image";
    } else if (fileMime?.startsWith("video/")) {
      return "video";
    } else {
      return "document";
    }
  }

  useEffect(() => {
    testimonials().then((res, rej) => {
      if (res.status === 200) {
        setProjects(res?.data?.testimonials);
      } else {
        console.log(rej, "this is rej");
      }
    });
  }, []);
  let settings = {
    arrows: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 2500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 745,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  const [selectedFilter, setSelectedFilter] = useState("residential");
  const [videoPreview, setVideoPreview] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [isReadMore, setIsReadMore] = useState(true);
  const [isModal, setModalVisible] = useState(false);
  const [para, setPara] = useState("");
  function handlePreviewVideo(file) {
    setVideoPreview(true);
    setVideoUrl(file);
  }
  window.onkeyup = function (event) {
    if (event.keyCode === 27) {
      setVideoPreview(false);
    }
  };
  const hanldeClose = () => {
    setVideoPreview(false);
    setVideoUrl("");
  };
  const toggleReadMore = (e) => {
    setModalVisible(true);
    setPara(e);
  };
  const handleCancel = () => {
    setModalVisible(false);
    setPara("");
  };
  const handleOk = () => {
    setModalVisible(false);
    setPara("");
  };

  const RenderFileType = ({ file, id }) => {
    const fileType = detectFileType(file);

    switch (fileType) {
      case "image":
        return (
          <img
            src={file}
            className='our-customer-speak-image'
            width='245px'
            height='242px'
            style={{
              cursor: "pointer",
              borderRadius: "10px",
              marginTop: "10%",
              marginLeft: "3%",
            }}
            alt='testimonials'
          />
        );
      case "video":
        return (
          <div style={{ position: "relative", width: "100%", height: "100%" }}>
            <video
              className='our-customer-speak-image'
              width='285px'
              height='202px'
              style={{
                cursor: "pointer",
                borderRadius: "10px",
                marginTop: "20%",
                marginLeft: "3%",
              }}
              onClick={() => handlePreviewVideo(file)}>
              <source src={file} autostart='false'></source>
            </video>
            <PlayButtonDiv onClick={() => handlePreviewVideo(file)}>
              <img src={PlayButton} alt='play' />
            </PlayButtonDiv>
          </div>
        );
      default:
        return "image";
    }
  };
  return (
    <PageSection
      title={"Our Customers Speak"}
      subTitle={"Handpicked projects for you"}
      titleTop={50}
      titleSubTitleSpace={5}
      titleBottom={50}
      style={{
        background: "#FBFFF5",
      }}>{" "}
      <div className='our-customer-speak-container'>
        <div style={{ flex: 1, width: 50, cursor: "pointer" }}>
          <div style={{}}>
            <Slider {...settings} ref={sliderRef}>
              {projects.map((d, i) => {
                return (
                  <div key={"k" + i}>
                    <div
                      className='our-customar-speaks'
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}>
                      <div className='our-customer-speak-card'>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "start",
                            justifyContent: "center",
                            flex: "0.5",
                          }}>
                          <RenderFileType file={d?.file} id={d?._id} />
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}>
                          <div className='our-customer-speak-quota'>
                            <div>
                              <svg
                                width='40'
                                height='32'
                                viewBox='0 0 40 32'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                  d='M13.4493 14.8284H3.54725C3.85969 12.7892 4.60266 10.8363 5.7287 9.09446C6.85474 7.35259 8.3391 5.86004 10.087 4.71213L13.0962 2.73501L11.247 0L8.23768 1.97712C5.70464 3.63143 3.62745 5.87296 2.19044 8.50279C0.753422 11.1326 0.00102061 14.0694 0 17.0526V28.0092C0 28.8831 0.354243 29.7212 0.9848 30.3392C1.61536 30.9572 2.47058 31.3043 3.36232 31.3043H13.4493C14.341 31.3043 15.1962 30.9572 15.8268 30.3392C16.4573 29.7212 16.8116 28.8831 16.8116 28.0092V18.1236C16.8116 17.2496 16.4573 16.4115 15.8268 15.7935C15.1962 15.1755 14.341 14.8284 13.4493 14.8284Z'
                                  fill='#209541'
                                />
                                <path
                                  d='M36.6378 14.8284H26.7357C27.0482 12.7892 27.7911 10.8363 28.9172 9.09446C30.0432 7.35259 31.5276 5.86004 33.2754 4.71213L36.2847 2.73501L34.4522 0L31.4262 1.97712C28.8931 3.63143 26.8159 5.87296 25.3789 8.50279C23.9419 11.1326 23.1895 14.0694 23.1885 17.0526V28.0092C23.1885 28.8831 23.5427 29.7212 24.1733 30.3392C24.8038 30.9572 25.6591 31.3043 26.5508 31.3043H36.6378C37.5295 31.3043 38.3847 30.9572 39.0153 30.3392C39.6458 29.7212 40.0001 28.8831 40.0001 28.0092V18.1236C40.0001 17.2496 39.6458 16.4115 39.0153 15.7935C38.3847 15.1755 37.5295 14.8284 36.6378 14.8284Z'
                                  fill='#209541'
                                />
                              </svg>
                            </div>
                            <div style={{ flex: 1 }}></div>
                            <div style={{}}>
                              <svg
                                width='40'
                                height='32'
                                viewBox='0 0 40 32'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                  d='M26.5507 14.8284H36.4528C36.1403 12.7892 35.3973 10.8363 34.2713 9.09446C33.1453 7.35259 31.6609 5.86004 29.913 4.71213L26.9038 2.73501L28.753 0L31.7623 1.97712C34.2954 3.63143 36.3725 5.87296 37.8096 8.50279C39.2466 11.1326 39.999 14.0694 40 17.0526V28.0092C40 28.8831 39.6458 29.7212 39.0152 30.3392C38.3846 30.9572 37.5294 31.3043 36.6377 31.3043H26.5507C25.659 31.3043 24.8038 30.9572 24.1732 30.3392C23.5427 29.7212 23.1884 28.8831 23.1884 28.0092V18.1236C23.1884 17.2496 23.5427 16.4115 24.1732 15.7935C24.8038 15.1755 25.659 14.8284 26.5507 14.8284Z'
                                  fill='#209541'
                                />
                                <path
                                  d='M3.36225 14.8284H13.2643C12.9518 12.7892 12.2089 10.8363 11.0828 9.09446C9.95678 7.35259 8.47243 5.86004 6.72457 4.71213L3.71529 2.73501L5.54775 0L8.57384 1.97712C11.1069 3.63143 13.1841 5.87296 14.6211 8.50279C16.0581 11.1326 16.8105 14.0694 16.8115 17.0526V28.0092C16.8115 28.8831 16.4573 29.7212 15.8267 30.3392C15.1962 30.9572 14.3409 31.3043 13.4492 31.3043H3.36225C2.47051 31.3043 1.61529 30.9572 0.984731 30.3392C0.354174 29.7212 -7.05719e-05 28.8831 -7.05719e-05 28.0092V18.1236C-7.05719e-05 17.2496 0.354174 16.4115 0.984731 15.7935C1.61529 15.1755 2.47051 14.8284 3.36225 14.8284Z'
                                  fill='#209541'
                                />
                              </svg>
                            </div>
                          </div>
                          <div className='our-customer-speak-tag-line'>
                            {d?.description.substr(0, 250)}
                            {d?.description.length > 250 && (
                              <ReadMoreDiv
                                onClick={() => toggleReadMore(d?.description)}>
                                Read More
                              </ReadMoreDiv>
                            )}
                          </div>
                          <div className='our-customer-speak-mobile-tag-line'>
                            {d?.description.substr(0, 150)}
                            {d?.description.length > 150 && (
                              <ReadMoreDiv
                                onClick={() => toggleReadMore(d?.description)}>
                                Read More
                              </ReadMoreDiv>
                            )}
                          </div>
                          <div className='our-customer-speak-sub-tag-line'>
                            {d.username}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // padding: 20,
              }}>
              <div style={{ width: 5 }} className='our-customers-speak-slider-action-holder'>
                <div
                  className='our-customers-speak-carousal-left-chevron-btn'
                  onClick={() => {
                    sliderRef.current.slickPrev();
                  }}>
                  <svg
                    width='10'
                    height='19'
                    viewBox='0 0 10 19'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M8.9375 1.47925L0.916667 9.50008L8.9375 17.5209'
                      stroke='#5D5F5F'
                      strokeWidth='1.8'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
                <div
                  className='our-customers-speak-carousal-right-chevron-btn'
                  onClick={() => {
                    sliderRef.current.slickNext();
                  }}>
                  <svg
                    width='10'
                    height='19'
                    viewBox='0 0 10 19'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M1.0625 17.5208L9.08333 9.49992L1.0625 1.47908'
                      stroke='#5D5F5F'
                      strokeWidth='1.8'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {videoPreview && (
        <VideoPreview>
          <PositionedCloseIcon onClick={hanldeClose} />
          <StyledVideo
            key={videoPreview}
            width='80%'
            height='80%'
            controls
            playsInline
            autoPlay>
            <source src={videoUrl} type='video/mp4'></source>
          </StyledVideo>
        </VideoPreview>
      )}
      <Modal
        open={isModal}
        onCancel={handleCancel}
        onOk={handleOk}
        footer={null}
        className="our-customer-speak-modal"
        >
        <div>{para}</div>
      </Modal>
    </PageSection>
  );
};

function loadprojects(cb) {
  var myHeaders = new Headers();
  myHeaders.append(
    "Cookie",
    "sails.sid=s%3AVMjWRXtLoViz1nDUyIXJhuFsyJAlPltr.pXO57A0bsp%2FQMBh6x22Ze5rrVm2tGzixJwqUy1q2nAc"
  );

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  fetch(APIBASE + "/testimonials", requestOptions)
    .then((response) => response.text())
    .then((result) => cb(result))
    .catch((error) => console.log("error", error));
}
export default Projects;
