import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { message } from "antd";
import style from "./css/index.module.css";
import WriteCommentCardStyle from "./css/WriteCommentCard.module.css";
import CommentCardStyle from "./css/CommentCard.module.css";
import ActionsStyle from "./css/Actions.module.css";
export default function Comments({ blogId, isVissble, toggleComment }) {
  const [comments, setComments] = useState([]);
  const [profilePic, setProfilePic] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();

  useEffect(() => {
    const CommentsData = () => {
      const url = `${process.env.REACT_APP_PATH}/api/comments/readComment/${blogId}`;
      axios
        .get(url)
        .then((response) => {
          return setComments(response.data);
        })
        .catch((error) => console.log(error));
    };
    CommentsData();

    setName(localStorage.getItem("name"));
    setEmail(localStorage.getItem("email"));
    setProfilePic(localStorage.getItem("profilePic"));
  }, []);

  // for creting new comment
  const handleNewCommentPost = (value) => {
    const newComment = {
      profilePic,
      email,
      name,
      comment: value,
    };
    if (value !== "" && value !==" ") {
      if (value !== undefined) {
        setComments((p) => [newComment, ...p]);
        const url = `${process.env.REACT_APP_PATH}/api/comments/${blogId}`;
        axios
          .post(url, newComment)
          .then((response) => true)
          .catch((error) => console.log(error));
      }
    } else {
      message.warning("Comment is empty");
    }
  };
  // for cancelling the new comment || hide comment section
  const handleCommentCancel = () => {
    toggleComment();
  };

  return (
    <>
      <div className={style.container}>
        <div className={style.view_comment} onClick={() => toggleComment()}>
          {comments.length > 0
            ? `View ${comments.length} previous comments`
            : `Write the first comment`}
        </div>
        {isVissble && (
          <>
            <WriteCommentCard
              handleCommentPost={handleNewCommentPost}
              handleCommentCancel={handleCommentCancel}
            />
            {comments.map((comment, index) => {
              return (
                <CommentCard
                  profilePic={comment?.profilePic}
                  name={comment?.name}
                  comment={comment?.comment}
                  time={comment?.time}
                  like={comment?.likes}
                  dislike={comment?.dislikes}
                  commentId={comment?._id}
                  isInitiallyLiked={comment?.isLiked}
                  isInitiallyDisliked={comment?.isDisliked}
                />
              );
            })}
          </>
        )}
      </div>
    </>
  );
}

export function CommentCard(props) {
  const {
    profilePic,
    name,
    comment,
    time,
    like,
    dislike,
    commentId,
    isInitiallyLiked = false,
    isInitiallyDisliked = false,
  } = props;
  const [isLiked, setLiked] = useState(isInitiallyLiked);
  const [isDisLiked, setDisLiked] = useState(isInitiallyDisliked);
  const [isActionsVissible, setActionsVissible] = useState(false);
  const [likeCount, setLikeCount] = useState(like);
  const [dislikeCount, setDislikeCount] = useState(dislike);
  // linked user name
  const [username, setUsername] = useState();
  const [email, setEmail] = useState();

  useEffect(() => {
    setUsername(localStorage.getItem("name"));
    setEmail(localStorage.getItem("email"));
  }, []);

  const handleLikeDislike = (type) => {
    if (type === "like") {
      if (!isLiked) {
        if (dislikeCount === 0) {
          setLiked(true);
          setDisLiked(false);
          setLikeCount((p) => p + 1);
        } else {
          setLikeCount((p) => p + 1);
          setDislikeCount((p) => p - 1);
          setLiked(true);
          setDisLiked(false);
        }
      }
    } else {
      if (!isDisLiked) {
        if (likeCount === 0) {
          setDislikeCount((p) => p + 1);
          setDisLiked(true);
          setLiked(false);
        } else {
          setLikeCount((p) => p - 1);
          setDislikeCount((p) => p + 1);
          setDisLiked(true);
          setLiked(false);
        }
      }
    }
    const data = {
      name: username,
      email: email,
      likeDislike: type,
    };
    const url = `${process.env.REACT_APP_PATH}/api/comments/like-dislike/${commentId}`;
    axios
      .post(url, data)
      .then()
      .catch((error) => console.log(error));
  };
  return (
    <>
      <div
        className={CommentCardStyle.main_wrapper}
        style={{ padding: "29px 39px 0 29px" }}
      >
        <Actions isVissible={isActionsVissible} />
        <div className={CommentCardStyle.wrapper}>
          <div className={CommentCardStyle.avatar_container}>
            <img src={profilePic} />
          </div>
          <div className={CommentCardStyle.comment_container}>
            <div className={CommentCardStyle.bottom_bg}></div>
            <div className={CommentCardStyle.comment_name}>
              {name}
              <div className={CommentCardStyle.comment_time}>{time}</div>
            </div>
            <div className={CommentCardStyle.main_comment}>{comment}</div>
            <div className={CommentCardStyle.actions}>
              <div className={CommentCardStyle.actions_group1}>
                <div className={CommentCardStyle.like_action}>
                  <img
                    src={`/assets/comments/${isLiked ? "liked" : "like"}.svg`}
                    onClick={() => handleLikeDislike("like")}
                  />
                  <div className={CommentCardStyle.likeCount}>{likeCount}</div>
                </div>
                <div className={CommentCardStyle.dislike_action}>
                  <img
                    src={`/assets/comments/${
                      isDisLiked ? "liked" : "like"
                    }.svg`}
                    onClick={() => handleLikeDislike("dislike")}
                  />
                  <div className={CommentCardStyle.disLikeCount}>
                    {dislikeCount}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function WriteCommentCard(props) {
  const [profilePic, setProfilePic] = useState(null);

  useEffect(() => {
    setProfilePic(localStorage.getItem("profilePic"));
  }, []);

  const { handleCommentPost, handleCommentCancel } = props;
  const inputValueRef = useRef("");
  return (
    <div
      className={WriteCommentCardStyle.wrapper}
    >
      <div
        className={WriteCommentCardStyle.input_container}
        style={{
          background: "rgba(0, 111, 215, 0.03)",
        }}
      >
        <div className={WriteCommentCardStyle.inputField}>
          <img src={profilePic} width={50} height={50} />
          <input
            type="text"
            placeholder="write your comment here"
            ref={inputValueRef}
          />
        </div>
        <div className={WriteCommentCardStyle.btns_container}>
          <button
            className={WriteCommentCardStyle.cancel_btn}
            onClick={handleCommentCancel}
          >
            CANCEL
          </button>
          <button
            className={WriteCommentCardStyle.post_btn}
            onClick={() => {
              handleCommentPost(inputValueRef.current.value);
              inputValueRef.current.value = "";
            }}
          >
            POST
          </button>
        </div>
      </div>
    </div>
  );
}

export function Actions({ isVissible = false }) {
  return (
    <>
      {false && (
        <div className={ActionsStyle.container}>
          <div className={ActionsStyle.edit}>Edit</div>
          <div className={ActionsStyle.delete}>Delete</div>
        </div>
      )}
    </>
  );
}
