import "./index.css";
import PageSection from "../global/PageSection";
import { APIBASE } from "../../apiCondig";

import upcommingbanner from "./images/Banner.png";
import smallupcommingbanner from "./images/small_banner.png";
import locationIcon from "./images/location.svg";

const UpcomingProjects = () => {
  return (
    <PageSection
      title={"Upcoming Projects"}
      subTitle={"Handpicked projects for you"}
      width="100%"
      titleBottom={63}
      style={{
        background: "#FBFFF5",
        position: "relative",
        zIndex: 7,
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)"
      }}
    >
      <div style={{ display: "flex" }}>
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            {/* <LocationIcon /> */}
            <img src={locationIcon} style={{ position: "absolute" }} className="location_icon_with_animation" alt="" />

            <img
              style={{
                position: "absolute",
                top: "40%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              alt=""
            />
            <img
              src={upcommingbanner}
              alt={"upcoming"}
              style={{ cursor: "pointer", width: "100%" }}
              className="location_img_for_desktop"
            />
            <img
              src={smallupcommingbanner}
              alt={"upcoming"}
              style={{ cursor: "pointer", width: "100%" }}
              className="location_img_for_responsive"
            />
          </div>
        </div>
      </div>
    </PageSection>
  );
};

function loadprojects(cb) {
  var myHeaders = new Headers();
  myHeaders.append(
    "Cookie",
    "sails.sid=s%3AVMjWRXtLoViz1nDUyIXJhuFsyJAlPltr.pXO57A0bsp%2FQMBh6x22Ze5rrVm2tGzixJwqUy1q2nAc"
  );

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  fetch(APIBASE + "/Properties", requestOptions)
    .then((response) => response.text())
    .then((result) => cb(result))
    .catch((error) => console.log("error", error));
}
export default UpcomingProjects;
