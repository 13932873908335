export const projectDropDown = [{
   id: 1,
   title: "All Projects",
   path: "/all-projects",
   cName: "nav-items",
},
{
   id: 2,
   title: "Residential Plot",
   path: "/project/63a1b47972f212666ad7547e",
   cName: "nav-items",
},
{
   id: 3,
   title: "Farm Land",
   path: "/farmland-projects",
   cName: "nav-items",
},
];