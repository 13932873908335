import "./index.css";
import backgroundImage from "../../assets/images/TrendingProjects/bg.png";
import backgroundImageRespo from "../../assets/images/TrendingProjects/bg-respo.png";
import PageSection from "../global/PageSection";
import { useEffect, useRef, useState } from "react";
import { APIBASE } from "../../apiCondig";
import Slider from "react-slick";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate } from "react-router-dom";
import { propertyData } from "../../api/golednProperty";

const TreandingProjects = () => {
  const [projects, setProjects] = useState([]);
  const sliderRef = useRef();

  let settings = {
    dots: false,
    arrows: false,
    slidesToShow: projects.length >= 5 ? projects.length - 1 : 5,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3500,
    cssEase: "linear",

    responsive: [
      {
        breakpoint: 1392,
        settings: {
          arrows: false,
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: false,
          autoplay: true,
          autoplaySpeed: 1500,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 750,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
          dots: false,
          autoplay: true,
          autoplaySpeed: 1500,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 520,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          autoplay: true,
          autoplaySpeed: 1500,
          cssEase: "linear",
        },
      },
    ],
  };
  useEffect(() => {
    propertyData("trending").then((res, rej) => {
      if (res.status === 200) {
        const data = res?.data?.properties;
        setProjects(data);
      } else {
        console.log(rej, "this is rej");
      }
    });
  }, []);
  return (
    <div className="treanding-projects">
      <PageSection
        title={"Trending Projects"}
        subTitle={"The  Noteworthy Real Estate in India"}
        // bgImage={backgroundImage}
        width={"100%"}
        titleTop={59}
        // titleSubTitleSpace={16}
        titleSubTitleSpace={9}
        titleBottom={61.91}
        style={{
          flex: 1,
          margin: 0,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right bottom",
          backgroundColor: "#FBFFF5",
          paddingBottom: 162,
          backgroundSize: "100%",
          position: "relative",
        }}
      >
        <img className="trending-projects-background" src={backgroundImage}
          style={{ zIndex: 0 }} alt="" />
        <img
          className="trending-projects-background-responsive"
          src={backgroundImageRespo}
          style={{ zIndex: 0 }}
          alt=""
        />
        <>
          <div style={{ display: "flex", margin: "auto" }}>
            <div style={{ flex: 1, width: 50 }}>
              <div>
                <Slider {...settings} ref={sliderRef}>
                  {projects.map((item, i) => {
                    return (
                      <TrendingCard
                        index={i}
                        link={`/project/${item.title==="GOLDENPROPERTY"?"63a1b47972f212666ad7547e":item?._id}`}
                        image={item?.image[0]}
                      />
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </>
      </PageSection>
    </div>
  );
};
export default TreandingProjects;

function loadprojects(cb) {
  var myHeaders = new Headers();
  myHeaders.append(
    "Cookie",
    "sails.sid=s%3AVMjWRXtLoViz1nDUyIXJhuFsyJAlPltr.pXO57A0bsp%2FQMBh6x22Ze5rrVm2tGzixJwqUy1q2nAc"
  );

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  fetch(APIBASE + "/Properties", requestOptions)
    .then((response) => response.text())
    .then((result) => cb(result))
    .catch((error) => console.log("error", error));
}

export function TrendingCard({ index, link, image }) {
  let navigate = useNavigate();
  return (
    <div
      key={"k" + index}
      onClick={() => {
        image?.includes("041a752e-1e1c-4fae-b4e3-4bf4ab0ecc23.png")
          ? navigate("/project/63a1b47972f212666ad7547e")
          : navigate(link);
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          // paddingBottom: 10,
          cursor: "pointer",
          margin: "10px 0",
        }}
      >
        <div
          className="trending-card"
          style={{
            overflow: "hidden",
            minWidth: "245px",
            // minWidth: 184.16,
            // width: "100%",
            //width: "50%",
            height: 142.22,
            borderRadius: 24,
            display: "flex",
            flexDirection: "column",
            background: "#fff",
            textAlign: "left",
            //paddingBottom: 5,
            // margin: "5px auto",
            // boxShadow:"0px -2px 2px rgba(208, 218, 244, 0.25), 0px 1px 3px #D0DAF4",
            boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <img
              src={image}
              style={{
                border: "#D9D9D9",
                borderRadius: "24px 24px 0px 0px",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
