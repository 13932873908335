const HighlightsTab = ({ unit }) => {
    if (!unit) unit = {};
    return (
      <div
        style={{
          margin: 10,
          marginTop: 19,
          border: "1px solid #ccc",
          borderRadius: 5,
          background: "#fff",
          padding: 25,
        }}
        id="ProjectDetailsTabUnits"
      >
        <div
          style={{
            fontWeight: 500,
            fontSize: 20,
            lineHeight: "14px",
            letterSpacing: "0.06em",
            color: "#212325",
          }}
        >
          Highlights
        </div>
        <div style={{ paddingTop: 22 }}>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {unit?.map((eachItems, index) => (
              <>
                <HighlightComponent
                  icon={eachItems?.unitImage}
                  title={eachItems?.unitTitle}
                  description={eachItems?.unitDetails}
                />
              </>
            ))}
          </div>
        </div>
      </div>
    );
  };
  export default HighlightsTab;


  const HighlightComponent = ({ icon, title, description }) => {
    return (
      <div style={{ width: 260, padding: 10 }}>
        <div style={{ display: "flex" }}>
          <div
            style={{
              // display: "flex",
              // alignItems: "center",
              // justidyContent: "center",
            }}
          >
            <img loading="lazy" srcset={icon} alt={title} style={{marginTop:10}} />
          </div>
  
          <div>
            <div
              style={{
                fontWeight: 400,
                fontSize: 15,
                lineHeight: "23px",
                letterSpacing: "0.04em",
                color: "#A0A0A0",
                marginBottom: 5,
                paddingLeft: 10,
              }}
            >
              {title}
            </div>
            <div
              style={{
                fontWeight: 400,
                fontSize: 13,
                lineHeight: "23px",
                letterSpacing: "0.04em",
                color: "#212325",
                paddingLeft: 10,
              }}
            >
              {description}
            </div>
          </div>
        </div>
      </div>
    );
  };