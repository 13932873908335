import "./index.css";

const index = ({ title, subTitle, titleTop=86,titleBottom=86, titleSubTitleSpace=22 }) => {
  return (
    <div className="page-section-header-container" style={{paddingTop: titleTop, paddingBottom: titleBottom}}>
      <div className="page-section-header-title" style={{marginBottom:titleSubTitleSpace}}>{title}</div>
      <div className="page-section-header-subtitle">{subTitle}</div>
    </div>
  );
};

export default index;
