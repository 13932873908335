const address = (
    <div>
    CANH INFRASTRUCTURE LTD 
    <div></div>
    Reg. off: No.854, RK Plaza,
    <div></div>
    New Kanthraj Urs Road, 
    <div></div>
    Opposite JSS law college, Saraswathipuram, Mysore - 570009.
    </div>
);
export default address;