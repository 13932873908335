import "./index.css";
import { Link } from "react-router-dom";
const BreadCrumbs = ({ path = [] }) => {
  return (
    <div
      className="bread-conatiner"
      style={{
        display: "flex",
        maxWidth: 1440,
        margin: "auto",
        paddingTop: 25,
        paddingBottom: 25,
      }}
    >
      {path.map((d, i) => {
        if (path.length !== i + 1)
          return (
            <>
              {d === "home" ? (
                <><Link to={`/`}><div key={"bc" + i} className={"bc-link"}>{d}</div></Link><div className={"bc-link"}>/</div></>
              ) : (
                <><Link to={d.toUpperCase()==="RESIDENTIAL PROJECTS"?"/#residential-section":d?.toUpperCase()==="FARMLAND PROJECTS"?"/farmland-projects":"/#residential-section"} key={"bc" + i} className={"bc-link"}>{d}</Link><div className={"bc-link"}>/</div></>
              )}
            </>
          );
      })}
      <div className={"bc-link-current"}>{path[path.length - 1]}</div>
    </div>
  );
};
export default BreadCrumbs;
