import "./goldenprop.css";
import { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { APIBASE } from "../../apiCondig";
import { useParams } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { propertyDetailsData } from "../../api/golednProperty";

// import components
import ContactWithUsPopup from "../../components/ContactWithUsPopup/index";
import RecommandationBar from "./RecommandationBar";
import BreadCrumbs from "../../components/BreadCrumbs";
import ConnectForm from "../../components/ConnectForm/ConnectForm";
import ImageGalary from "./ImageGalary";
import Tab from "./Tab";
import AboutTab from "./AboutTab";
import HighlightsTab from "./HighlightsTab";
import AmenitiesTab from "./AmenitiesTab";
import BroucherTab from "./BroucherTab";
import LocalityTab from "./LocalityTab";
import OtherProjectsV2 from "../../components/OtherProjectsV2";



const GoldenProp = ({ id }) => {
  const [project, setProject] = useState({
    units: [],
    about: [],
    amenities: [],
    image: [],
    title: "",
    location: "",
    localities: [],
    brochure: [],
  });
  const [selectedTab, setselectedTab] = useState("About");
  const divRef = useRef();
  const params = useParams();
  var paramid = params.id;

  useEffect(() => {
    propertyDetailsData(paramid).then((res, rej) => {
      if (res.status === 200) {
        setProject(res?.data?.properties);
      } else {
        console.log(rej, "this is rej");
      }
    });
    const element = document.getElementById("property-details-page");
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  }, [paramid]);

  const movingCarRef = useRef();
  const imageHoverHandler = () => {
    movingCarRef.current.src = "/assets/ContactWithUs/kmp-moving.gif";
  };
  const imageHoverOutHandler = () => {
    movingCarRef.current.src = "/assets/ContactWithUs/kmp-nonmoving.png";
  };

  return (
    <div id="property-details-page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${project.title} | CANH INFRA`}</title>
        <link rel="canonical" href={`https://canhinfra.com/project/${project._id}`} />
      </Helmet>
      <ContactWithUsPopup infinite={false} />
      <div style={{ background: "#F8FFF9" }}>
        <BreadCrumbs
          path={["home", `${project.type} Projects`, project.title]}
        />
        {/* {JSON.stringify(project)} */}
        <div className="container-props">
          <div className="propert-container" ref={divRef}>
            {project.type === "residential" && (
              <RecommandationBar type="residential" activeId={paramid} />
            )}
            <ImageGalary
              images={
                project.type === "trending"
                  ? project.image.slice(1)
                  : project.type === "residential"
                    ? [project.image[0], ...project.image.slice(2)]
                    : project.image
              }
              title={project.title}
              location={project.location}
              type={project.type}
            />
            <Tab selectedTab={selectedTab} setselectedTab={setselectedTab} />
            <AboutTab about={project.about} />
            <HighlightsTab unit={project.units} />
            <AmenitiesTab amenities={project.amenities} />
            <BroucherTab
              images={project.image}
              title={project.title}
              pdf={project.brochure[0]}
              download={project.type === "residential" ? false : true}
            />
            <LocalityTab
              locality={project.localities}
              location={project.location}
            />
          </div>
          <div className="image-color">
            <div className="connect-container">
              <div>
                <div className="connect-info-form-container">
                  <div className="connect-info-header">Connect with us</div>
                  <ConnectForm />
                  <div className="car-image">
                    <img
                      src={"/assets/ContactWithUs/kmp-nonmoving.png"}
                      alt="car"
                      className="cantactus-car-image"
                      ref={movingCarRef}
                      onMouseOver={imageHoverHandler}
                      onMouseOut={imageHoverOutHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="other-project">
          <OtherProjectsV2 projectType={project.type} />
        </div>
      </div>
    </div>
  );
};
export default GoldenProp;

function loadProjectDetails(id, cb) {
  var myHeaders = new Headers();
  myHeaders.append(
    "Cookie",
    "sails.sid=s%3AVMjWRXtLoViz1nDUyIXJhuFsyJAlPltr.pXO57A0bsp%2FQMBh6x22Ze5rrVm2tGzixJwqUy1q2nAc"
  );

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  fetch(APIBASE + "/Properties/" + id, requestOptions)
    .then((response) => response.text())
    .then((result) => cb(result))
    .catch((error) => console.log("error", error));
}
