import React, { useState } from "react";
import style from "./style.module.css";
import toggleIcon from "../../assets/images/MarketedBy/toggleIcon.svg";

export default function MarketedBy({ logo, marketedBy, mobile }) {
  const [hidden, setHidden] = useState(false);
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  return (
    <div className={style.container}>
      <div
        className={style.item_wrapper}
        style={{
          width: hidden ? "0" : vw > 700 ? "450px" : "260px",
          padding: hidden ? "0" : "9.5px",
        }}
      >
        <div className={style.item}>
          <div className={style.title}>Marketed by :</div>
          <img src={logo} className={style.company_logo} alt=""></img>
          <div className={style.text_container}>
            <span className={style.text_name}>{marketedBy}</span>
          </div>
        </div>
      </div>
      <div
        className={style.toggleBtnContainer}
        onClick={() => setHidden((prev) => !prev)}
      >
        <img src={toggleIcon} className={style.toggleBtn} style={{ rotate: hidden ? "180deg" : "0deg" }} alt="" />
      </div>
    </div>
  );
}
