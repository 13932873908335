
const AmenitiesTab = ({ amenities }) => {
    return (
      <div
        style={{
          margin: 10,
          marginTop: 19,
          border: "1px solid #ccc",
          borderRadius: 5,
          background: "#fff",
          padding: 32,
        }}
        id="ProjectDetailsTabAmenities"
      >
        <div
          style={{
            fontWeight: 500,
            fontSize: 20,
            lineHeight: "14px",
            letterSpacing: "0.06em",
            color: "#212325",
          }}
        >
          Amenities
        </div>
        {/* <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginTop: 10,
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        > */}
        {/* <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, 115px)", justifyContent:"space-around", gridGap:"16px 12px", marginTop:30}} > */}
        <div className="amenities-card-container">
          {amenities.map((d, i) => {
            return (
              <div key={"ame" + i}>
                <AmenitiesCard title={d?.amenitiesTitle} icon={d?.amenitiesImage} />
              </div>
            );
          })}
        </div>
      </div>
    );
  };



const AmenitiesCard = ({ title, icon }) => {
    return (
      <div
        style={{
          maxWidth: 145,
          height: "100%",
          padding: 12,
          textAlign: "center",
          background: "#FFFFFF",
          boxShadow: "0px 0px 12.988px rgba(0, 0, 0, 0.06)",
          borderRadius: 10,
          // margin: "11px 10px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 20,
          }}
        >
          <img loading="lazy" srcset={icon} alt={title} width="45px" height="45px"/>
        </div>
        <div
          style={{
            fontWeight: 500,
            fontSize: 13,
            lineHeight: "14px",
            textAlign: "center",
            letterSpacing: "0.04em",
            color: "#000000",
            width: 96,
            margin: "auto",
          }}
        >
          {title}
        </div>
      </div>
    );
  };


  export default AmenitiesTab;