import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet';

// import images
import dakshalogo from "../../assets/images/MarketedBy/daksha.png";
// import components
import MarketedBy from "../../components/MarketedBy/index";
import FloatingWhatsUp from "../../components/FloatingWhatsUp/index";
import ContactWithUsPopup from "../../components/ContactWithUsPopup/index";
import Navbar from "../../components/NavBar";
import HomeSlider from "../../components/HomeSlider";
import TreandingProjects from "../../components/TrendingProjects";
import TopProjects from "../../components/TopProjects/index";
import OurProjects from "../../components/OurProjects/index";
import Amenities from "../../components/Amenities";
import WhyInvestInMysuru from "../../components/WhyInvestInMysuru";
import WhyUs from "../../components/whyUS";
import UsefulInformation from "../../components/UsefulInformation/index";
import OurCustomersSpeak from "../../components/OurCustomersSpeak";
import UpcomingProjects from "../../components/UpcomingProjects";
import Award from "../../components/Award";
import ContactInfo from "../../components/ContactInfo";
import FooterSection from "../../components/FooterSection";


export default function Home() {
  const location = useLocation();
  const [selectedFilter, setSelectedFilter] = useState("farmland");

  function updateProjectFilter(value) {
    setSelectedFilter(value);
  }
  useEffect(() => {
    updateProjectFilter();
    if (location.hash === "#awards") {
      const element = document.getElementById("awards");
      element.scrollIntoView({ behavior: "smooth" });
    }
    if (location.hash === "#contact") {
      const element = document.getElementById("contact");
      element.scrollIntoView({ behavior: "smooth" });
    }
    if (location.hash === "#residential-section") {
      const element = document.getElementById("residential-section");
      element.scrollIntoView({ behavior: "smooth" });
    }
    if (location.hash === "#project-card") {
      const element = document.getElementById("project-card");
      element.scrollIntoView();
    }
    if (location.hash === "") {
      const element = document.getElementById("home");
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`CANH INFRA`}</title>
        <meta name="description" content="CANH Infrastructure Ltd., is a project catering to the needs of common man. It offers Residential plots at Mysuru – Hunsur NH 275 near Manuganahalli Checkpost. A destination that is favourite among residents of Musuru and surrounding areas."></meta>
        <link rel="canonical" href={`https://canhinfra.com/`} />
      </Helmet>

      <div className="App" style={{ overflowY: "scroll" }}>
        <div id="home" style={{ width: "100%" }}></div>
        <Navbar updateProjectFilter={updateProjectFilter} />
        <HomeSlider />
        <MarketedBy marketedBy="DAKSHA PROPERTIES" logo={dakshalogo} />
        <TreandingProjects />
        <FloatingWhatsUp />
        <ContactWithUsPopup duration={60000} infinite={false} />
        <TopProjects />
        <OurProjects
          updateProjectFilter={updateProjectFilter}
          filter={selectedFilter}
        />
        <Amenities />
        <WhyInvestInMysuru />
        <WhyUs />
        <UsefulInformation />
        <OurCustomersSpeak />
        <UpcomingProjects />
        <Award />
        <ContactInfo />
        <FooterSection />
      </div>
    </>
  );
}
