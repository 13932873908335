import { DownloadOutlined } from "@ant-design/icons";
import NoFileIcon from "../../assets/PropertyDetails/no-file.svg";

const BroucherTab = ({ images, title, pdf, download }) => {
    const imageSource = images?.slice(0, 2);
    return (
      <div
        style={{
          margin: 10,
          marginTop: 19,
          border: "1px solid #ccc",
          borderRadius: 5,
          background: "#fff",
          padding: 25,
        }}
        id="ProjectDetailsTabBroucher"
      >
        <div className="where_from">{title} -Brochure</div>
        {imageSource?.length >= 1 && download ? (
          <>
            <div className="broucher_container">
              <div>
                <img
                  src={imageSource[0]}
                  alt="broucher"
                  className="broucher_image-container"
                />
              </div>
              {!imageSource[1] ? null : (
                <div>
                  <img
                    src={imageSource[1]}
                    alt="broucher"
                    className="broucher_image-container"
                  />
                </div>
              )}
              <div className="download_button">
                <button
                  className="download_option"
                  style={{
                    background: download ? "#209541" : "#C4C4C4",
                    border: "none",
                  }}
                  onClick={() => {
                    if (download) {
                      if (pdf) {
                        fetch(pdf).then((response) => {
                          response.blob().then((blob) => {
                            const fileURL = window.URL.createObjectURL(blob);
                            let alink = document.createElement("a");
                            alink.href = fileURL;
                            alink.download = `${title}.pdf`;
                            alink.click();
                          });
                        });
                      } else {
                        console.log("no pdf aviable for this property!!");
                      }
                    } else {
                      console.log("unable to download pdf!!");
                    }
                  }}
                >
                  <DownloadOutlined style={{ fontSize: "20px" }} />
                  {!download && <div>UNABLE TO DOWNLOAD</div>}
                  {download && <div>DOWNLOAD BROCHURE</div>}
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className="broucher_unable_container"
              style={{
                height: 314,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background:
                  "linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #EDEDED",
              }}
            >
              <img
                src={NoFileIcon}
                style={{ width: 109.29, height: 109, marginTop: 69 }}
              />
              <div className="unable_download_button" style={{ marginTop: 14 }}>
                <button
                  className="download_option"
                  style={{
                    background: "#209541",
                    border: "none",
                    opacity: 0.3,
                    color: "#fff",
                  }}
                >
                  <DownloadOutlined style={{ fontSize: "20px" }} />
                  <div>UNABLE TO DOWNLOAD</div>
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  export default BroucherTab;