import React, { useState, useEffect } from "react";
import Style from "./css/index.module.css";
import Navbar from "../../components/NavBar/index";
import FooterSection from "../../components/FooterSection/index";
import { ProjectCard } from "../../components/OurProjects/index";
import axios from "axios";
import ContactWithUsPopup from "../../components/ContactWithUsPopup/index";

export default function AllProjectsPage() {
  const [projectData, setProjectData] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_PATH}/api/properties/read`)
      .then(function (response) {
        const filteredArray = response?.data?.properties.filter((e, index) => {
          return e.type !== "residential" && e.type !== "trending" &&  e._id !== "63c2d8a1472efbc0b7e6f53e";
        });
        const staticData = {
          "_id": "63a1b47972f212666ad7547e",
          "image": ["/assets/ProjectUnderLogo/residential/golden-prop.png"],
          "title": "GOLDENPROPERTY",
          "description": `GOLDENPROPERTY CASTLE  a concern of CANH Infrastructure 
          Ltd., is a project catering to the needs of common man. It offers
          Residential plots at Mysuru – Hunsur NH 275  near 
          Manuganahalli Checkpost. A destination that is   favourite 
          among residents of Musuru and surrounding areas .This is a 
          project to match your lifestyle requirements. We have  been 
          serving clients for a number of years.`,
          "type": "trending",
          "location": "",
          "lan": "NA",
          "lat": "NA",
          "about": [],
          "units": [],
          "amenities": [],
          "localities": [],
          "brochure": [],
          "createdAt": "2023-01-14T16:30:25.981Z",
          "updatedAt": "2023-01-14T16:30:25.981Z",
          "__v": 0
      }
        setProjectData([staticData, ...filteredArray]);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  

  return (
    <>
    <ContactWithUsPopup infinite={false}/>
      <div>
        <Navbar />
        <div className={Style.container}>
          {projectData.map((item) => {
            if (item.title.toUpperCase()==="GOLDENPROPERTY") {
              item.logo = "/assets/ProjectUnderLogo/GOLDENPROPERTY.png";
            }
            return (<div className={Style.item_wrapper}>
            <ProjectCard
              image={item?.image[0]}
              logo={item?.logo}
              title={item?.title}
              description={item?.description}
              link={`/project/${item?._id}`}
              width="100%"
            />
            </div>)
          })}
        </div>
        <FooterSection />
      </div>
    </>
  );
}
