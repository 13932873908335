import "./award.css";
import { useRef } from "react";
import Slider from "react-slick";
import { useState, useEffect } from "react";
import AwardImage from "./AwardImage";
import { AwardsData } from "../../api/golednProperty";

const AwardsReceive = () => {
  const sliderRef = useRef();
  const [ourprojectimageData, setOurprojectimageData] = useState([]);
  useEffect(() => {
    AwardsData().then((res, rej) => {
      if (res.status === 200) {
        setOurprojectimageData(res?.data?.awards);
      } else {
        console.log(rej);
      }
    });
  }, []);
  let settings = {
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1392,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,

          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
      {
        breakpoint: 1160,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,

          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
      {
        breakpoint: 745,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          // autoplay: true,
          // autoplaySpeed: 3000,
        },
      },
    ],
  };
  return (
    <div className="award" id="awards">
      <div className="award-container">
        <div className="award-header">Awards Recieved</div>
        <div className="award-sub-header">
          We have been recieved many awards
        </div>

        <div
          style={{
            display: "flex",
          }}
        >
          <div style={{ flex: 1, minWidth: 200 }}>
            <div>
              <div style={{ flex: 1, minWidth: 200 }}>
                <Slider ref={sliderRef} {...settings}>
                  {ourprojectimageData.map((d, i) => {
                    return (
                      <AwardImage
                        key={i}
                        image={d?.image}
                        icon={d?.icon}
                        title={d?.title}
                        subtitle={d?.subTitle}
                      />
                    );
                  })}
                </Slider>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{ width: 5 }}
                    className="award-slider-action-holder"
                  >
                    <div
                      className="award-carousal-left-chevron-btn"
                      onClick={() => {
                        sliderRef.current.slickPrev();
                      }}
                    >
                      <svg
                        width="10"
                        height="19"
                        viewBox="0 0 10 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.9375 1.47925L0.916667 9.50008L8.9375 17.5209"
                          stroke="#5D5F5F"
                          strokeWidth="1.8"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div
                      className="award-carousal-right-chevron-btn"
                      onClick={() => {
                        sliderRef.current.slickNext();
                      }}
                    >
                      <svg
                        width="10"
                        height="19"
                        viewBox="0 0 10 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.0625 17.5208L9.08333 9.49992L1.0625 1.47908"
                          stroke="#5D5F5F"
                          strokeWidth="1.8"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>

                {/* from other project */}
                <div
                  style={{}}
                  className="award-slider-small-action-holder"
                >
                  <div
                    className="award-carousal-left-chevron-btn-responsive"
                    style={{ marginTop: 0, left: 0 }}
                    onClick={() => {
                      sliderRef.current.slickPrev();
                    }}
                  >
                    <svg
                      width="10"
                      height="19"
                      viewBox="0 0 10 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.9375 1.47925L0.916667 9.50008L8.9375 17.5209"
                        stroke="#5D5F5F"
                        strokeWidth="1.8"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div style={{ width: 18 }}></div>
                  <div
                    className="award-carousal-right-chevron-btn-responsive"
                    style={{ marginTop: 0, left: 0 }}
                    onClick={() => {
                      sliderRef.current.slickNext();
                    }}
                  >
                    <svg
                      width="10"
                      height="19"
                      viewBox="0 0 10 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.0625 17.5208L9.08333 9.49992L1.0625 1.47908"
                        stroke="#5D5F5F"
                        strokeWidth="1.8"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AwardsReceive;
