import React from "react";
import SocialLogin from "react-social-login";

class SocialButton extends React.Component {
  render() {
    const { children, triggerLogin, ...props } = this.props;
    return (
      <button
        onClick={triggerLogin}
        {...props}
        style={{
          border: "1px solid rgba(0, 0, 0, 0.3)",

          width: "100%",

          background: "#FFFFFF",
          borderRadius: " 6px",
          color: "#0d1216",

          height: "46px",

          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "14px",
          fontWeight: "400",
          gap: "5px",
        }}>
        {children}
        <img
          src='https://www.freepnglogos.com/uploads/google-logo-png/google-logo-png-suite-everything-you-need-know-about-google-newest-0.png'
          alt='Google'
          width={"30px"}
        />
        Login with Google
      </button>
    );
  }
}

export default SocialLogin(SocialButton);
