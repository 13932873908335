import styled from "styled-components";
import ReactQuill from "react-quill";


const AboutTab = ({ about = [] }) => {
  const StyledReactMarkDown = styled(ReactQuill)`
    font-style: normal;
    font-weight: 400;
    font-size: 10px !important;
    line-height: 22px;
    text-align: justify;
    letter-spacing: -0.1px;
    color: rgba(11, 11, 18, 0.87);
    padding: none !important;
  `;
  const LocationDiv = styled.div`
  position: absolute;
  left: -20px;
  background-color: white;
  width: 36.14px;
  height: 36.14px;
  border-radius: 25.1417px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
  return (
    <div
      style={{
        margin: 10,
        marginTop: 19,
        border: "1px solid #ccc",
        borderRadius: 5,
        background: "#fff",
        padding: 25,
      }}
      id="ProjectDetailsTabAbout"
    >
      <div
        style={{
          fontWeight: 500,
          fontSize: 20,
          lineHeight: "14px",
          letterSpacing: "0.06em",
          color: "#212325",
        }}
      >
        About
      </div>
      <div style={{ paddingTop: 28 }}>
        {(!about || about.length === 0) && "No data Found"}
        {about.map((d, i) => {
          return (
            <div style={{ display: "flex", marginTop: "18px" }}>
              <div style={{ paddingRight: 9.28 }}>
                <svg
                  width="22"
                  height="20"
                  viewBox="0 0 22 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.903625 11.3L11.1084 1.09521L21.3133 11.3"
                    stroke="#209541"
                    stroke-width="1.20602"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.17126 9.0332V16.9703C3.17126 17.5717 3.41019 18.1485 3.83547 18.5738C4.26075 18.9991 4.83756 19.238 5.439 19.238H16.7777C17.3791 19.238 17.9559 18.9991 18.3812 18.5738C18.8065 18.1485 19.0454 17.5717 19.0454 16.9703V9.0332"
                    stroke="#209541"
                    stroke-width="1.20602"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.70673 12.4335L9.97446 14.7012L14.5099 10.1658"
                    stroke="#209541"
                    stroke-width="1.20602"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <StyledReactMarkDown value={d} theme={"bubble"} readOnly />
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default AboutTab;
