import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const DropdownContainer = styled.div`
  margin-left: 2.5vw;
  cursor: pointer;
  color: ${(props) => (props.active ? "#16864A" : "  #212325")};
  text-align: ${(props) => (props.center && "center") || "left"};
  padding-bottom: ${(props) => (props.center && "0") || "0.45em"};
  text-transform: uppercase;
  transition: 0.5s;
  width: fit-content;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  &:after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 60%;
    padding-top: 0.45em;
  }
  &:hover {
    color: ${"#16864A"};
  }
`;
const DropdownWrapper = styled.ul`
  list-style-image: url("/assets/Navbar/next.svg");
  padding-left: 20px;
  margin-top: 12px;
`;
const DropdownItem = styled.li`
  margin-top: 19px;
`;
const PageLink = styled(Link)`
  color: black;
`;
export default function DropdownMobileRespo() {
  const [isVissible, setVissible] = useState(false);
  return (
    <>
      <DropdownContainer>
        <div onClick={() => setVissible((p) => !p)}>PROJECTS</div>
        {isVissible && (
          <DropdownWrapper>
            <DropdownItem>
              <PageLink to="/all-projects">All Projects</PageLink>
            </DropdownItem>
            <DropdownItem>
              <PageLink to="/project/63a1b47972f212666ad7547e">
                Residential Plot
              </PageLink>
            </DropdownItem>
            <DropdownItem>
              <PageLink to="/farmland-projects">Farm Land</PageLink>
            </DropdownItem>
          </DropdownWrapper>
        )}
      </DropdownContainer>
    </>
  );
}
