import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import BlogCard from "./BlogCard";
import ConnectForm from "../../components/ConnectForm/ConnectForm";
import { blogsData } from "../../api/golednProperty";
import { useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet';

const MainDiv = styled.div`
  display: flex;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 50px;
  padding-left: 10px;
  flex-direction: row;
  background: rgba(251, 255, 245, 1);
`;
const FormDiv = styled.div`
  position: absolute;
  z-index: 1;
  // top: 100px;
  // margin: 7px;
  // margin-top: 0;
  left: 64%;
  background: #ffffff;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 2px 20px rgba(67, 81, 1, 0.22);
  width: 418px;
  height: 536px;
  @media (min-width: 1000px) and (max-width: 1150px) {
    top: 40px;
    left: 60%;
    padding: 10px;
  }
  @media (min-width: 1151px) and (max-width: 1300px) {
    top: 110px;
    left: 64%;
    padding: 20px;
    width: 360px;
    height: 80vh
  }
  @media (max-width: 778px) {
    display: none;
  }
`;
const ContentDiv = styled.div`
  width: 63%;
  @media (min-width: 1000px) and (max-width: 1150px) {
    width: 58%;
  }
  @media (min-width: 1151px) and (max-width: 1300px) {
    width: 62%;
  }
  @media (max-width: 778px) {
    width: 100%;
    padding-left: 0px;
    padding-right: 10px;
  }
`;

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const params = useLocation();
  const scrollToElement = (params) => {
    var element = document.getElementById(params);
    element.scrollIntoView();
  };

  useEffect(() => {
    blogsData().then((res, rej) => {
      if (res.status === 200) {
        setBlogs(res?.data?.blogs);
      } else {
        console.log(rej, "this is rej");
      }
    });
  }, []);
  const handleRefresh = () => {
    blogsData().then((res, rej) => {
      if (res.status === 200) {
        setBlogs(res?.data?.blogs);
      } else {
        console.log(rej, "this is rej");
      }
    });
  };

  useEffect(() => {
    if (params.hash && blogs.length > 0) {
      scrollToElement(params.hash.slice(1));
    }
  }, [blogs]);

  const movingCarRef = useRef();
  const imageHoverHandler = () => {
    movingCarRef.current.src = "/assets/ContactWithUs/kmp-moving.gif";
  }
  const imageHoverOutHandler = () => {
    movingCarRef.current.src = "/assets/ContactWithUs/kmp-nonmoving.png";
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Blogs || CANH INFRA`}</title>
        <meta name="description" content="CANH Infrastructure Ltd., is a project catering to the needs of common man. It offers Residential plots at Mysuru – Hunsur NH 275 near Manuganahalli Checkpost. A destination that is favourite among residents of Musuru and surrounding areas."></meta>
        <link rel="canonical" href={`https://canhinfra.com/blogs`} />
      </Helmet>
      <MainDiv>
        <ContentDiv>
          <BlogCard cardDetails={blogs} handleRefresh={handleRefresh} />
        </ContentDiv>
        <FormDiv>
          <div className="connect-info-header">Connect with us</div>
          <ConnectForm />
          <div className="car-image">
            <img src={"/assets/ContactWithUs/kmp-nonmoving.png"} alt="car" className="cantactus-car-image" ref={movingCarRef} onMouseOver={imageHoverHandler} onMouseOut={imageHoverOutHandler} />
          </div>
        </FormDiv>
      </MainDiv>
    </>
  );
};

export default Blogs;
