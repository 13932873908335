const residentialData = [
    {
        "_id": "63a1a88c72f212666ad72130",
        "image": [
            "https://res.cloudinary.com/dad22bylk/image/upload/v1675788652/Canhinfra/185e64fb-b635-400f-8099-a9f6d9a17e05.webp"
        ],
        "title": "GP-Premium Phase 1",
        "description": "Surrounded with greens, the GOLDENPROPERTY – PREMIUM PHASE 1 comprising of 30 plots prevailing at Hullenahalli, Bilikere Hobli, Hunsur Taluk,  offers you to choose the ideal way of unwinding from the host of amenities.",
        "type": "residential",
        "location": "Mysuru",
        "logo": ""
    },
    {
        "_id": "63a1ad8272f212666ad73132",
        "image": [
            "https://res.cloudinary.com/dad22bylk/image/upload/v1675790209/Canhinfra/6fa30749-6c43-4293-bbe6-cec51d37ecf7.webp"
        ],
        "title": "GP-Premium Phase 2",
        "description": "Surrounded with greens, the GOLDENPROPERTY – PREMIUM PHASE 2 comprising of 30 plots prevailing at Hullenahalli, Bilikere Hobli, Hunsur Taluk,  offers you to choose the ideal way of unwinding from the host of amenities.",
        "type": "residential",
        "location": "Mysuru",
        "logo": ""
    },
    {
        "_id": "63a1b09072f212666ad73b1c",
        "image": [
            "https://res.cloudinary.com/dad22bylk/image/upload/v1675789898/Canhinfra/33d7a51a-664f-4dc9-b8cf-9d7c139126e1.webp"
        ],
        "title": "GP-Premium Phase 3",
        "description": "Surrounded with greens, the GOLDENPROPERTY – PREMIUM PHASE 3 comprising of 30 plots prevailing at Devarahalli, Bilikere Hobli, Hunsur Taluk,  offers you to choose the ideal way of unwinding from the host of amenities.",
        "type": "residential",
        "location": "Mysuru",
        "logo": ""
    },
    {
        "_id": "63a1b47972f212666ad7547e",
        "image": [
          "https://res.cloudinary.com/dad22bylk/image/upload/v1675784063/Canhinfra/03c6cd8a-8c30-4991-9e4c-c77313df693c.webp"
        ],
        "title": "GOLDENPROPERTY – SECTOR 1",
        "description": "Surrounded with greens, the GOLDENPROPERTY – SECTOR 1 comprising of 30 plots prevailing at Devarahalli, Bilikere Hobli, Hunsur Taluk,  offers you to choose the ideal way of unwinding from the host of amenities.",
        "type": "residential",
        "location": "Devarahalli, Bilikere Hobli, Hunsur Taluk",
        "logo": ""
      },
    {
        "_id": "63a1b03a72f212666ad7397d",
        "image": [
            "https://res.cloudinary.com/dad22bylk/image/upload/v1675785724/Canhinfra/8c620c5d-0488-4793-8ae8-e0867520b2d0.webp"
        ],
        "title": "GOLDENPROPERTY – SECTOR 2",
        "description": "Surrounded with greens, the GOLDENPROPERTY – SECTOR 2 comprising of 30 plots prevailing at Devarahalli, Bilikere Hobli, Hunsur Taluk,  offers you to choose the ideal way of unwinding from the host of amenities.",
        "type": "residential",
        "location": "Devarahalli, Bilikere Hobli, Hunsur Taluk",
        "logo": ""
    },
    {
        "_id": "63a1b10b72f212666ad73ea5",
        "image": [
            "https://res.cloudinary.com/dad22bylk/image/upload/v1675785724/Canhinfra/8c620c5d-0488-4793-8ae8-e0867520b2d0.webp"
        ],
        "title": "GOLDENPROPERTY – SECTOR 3",
        "description": "Surrounded with greens, the GOLDENPROPERTY – SECTOR 3 comprising of 30 plots prevailing at Devarahalli, Bilikere Hobli, Hunsur Taluk,  offers you to choose the ideal way of unwinding from the host of amenities.",
        "type": "residential",
        "location": "Devarahalli, Bilikere Hobli, Hunsur Taluk",
        "logo": ""
    },
    {
        "_id": "63a1acb072f212666ad72baa",
        "image": [
            "https://res.cloudinary.com/dad22bylk/image/upload/v1675787241/Canhinfra/e05014cb-593d-401c-8bdd-cea10e968d5d.webp"
        ],
        "title": "GOLDENPROPERTY – SECTOR 4",
        "description": "Surrounded with greens, the GOLDENPROPERTY – SECTOR 4 comprising of 30 plots prevailing at Manuganahalli, Bilikere Hobli, Hunsur Taluk,  offers you to choose the ideal way of unwinding from the host of amenities.",
        "type": "residential",
        "location": "Manuganahalli, Bilikere Hobli, Hunsur Taluk",
        "logo": ""
    },
    
];

export default residentialData;