import React, {useState ,useRef, useEffect} from "react";
import { Link } from 'react-router-dom';
import axios from "axios";
import Slider from "react-slick";
import topprojectsStyles from "./css/index.module.css";

export default function TopProjects() {
  const sliderRef = useRef();
var settings = {
  dots: false,
  arrows: false,
  // speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1392,
      settings: {
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 745,
      settings: {
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2,
        infinite: true,
      },
    },
    {
      breakpoint: 500,
      settings: {
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
  ],
};
  const [topProjectsData, setTopProjectsData] = useState([])
useEffect(() => {
  function fetchTopProjects() {
    const url = `${process.env.REACT_APP_PATH}/api/topProjects`;
      axios
        .get(url)
        .then((response) => {
          return setTopProjectsData(response.data.topProjects)
        })
        .catch((error) => console.log(error));
  }
  fetchTopProjects();
}, [])


  return (
    <div className={topprojectsStyles.container} id="top-projects">
      <h1 className={topprojectsStyles.main_title}>Top Projects</h1>

      <div className={topprojectsStyles.banner_wrapper}>
      <Slider {...settings} ref={sliderRef}>
        {topProjectsData.map((item)=>{
        return <Link to={"/farmland-projects"}>
          <img src={item?.image} alt="top projects banner" className={topprojectsStyles.banner}/>
        </Link>
})}
      </Slider>
      </div>
    </div>
  );
}
