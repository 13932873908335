import "./index.css";
import personBG from "../../assets/images/personBG.png";
import PageSection from "../global/PageSection";
import bin from "./images/bin.svg";
import education from "./images/education.svg";
import hospital from "./images/hospital.svg";
import map from "./images/map.svg";
import train from "./images/train.svg";
import sunnyCloude from "./images/sunnyCloude.svg";
import waterPipe from "./images/waterPipe.svg";
import safe from "./images/safe.svg";
import Flight from "./images/Flight.svg";
import Road from "./images/Road.svg";

const WhyInvestInMysuru = () => {
  return (
    <div className='Why-invest-in-mysuru'>
      <PageSection
        title={"Why Invest in Mysuru"}
        subTitle={"The  Noteworthy Real Estate in India"}
        // bgImage={bgImage}
        style={{
          flex: 1,
          minHeight: 885,
          backgroundSize: "cover",
          margin: 0,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right bottom",
          backgroundColor: "#FBFFF5",
          display: "flex",
        }}>
        <div style={{ display: "flex" }} className='why-invest-cards-container'>
          <div className='why-invest-points-container-small'>
            <div
              style={{
                width: 300,
                height: 175,
                paddingTop: 10,
                borderBottom: "1px dashed #209541",
                margin: "auto",
              }}>
              <div style={{ display: "flex" }}>
                <div>
                  <img src={waterPipe} alt={"waterPipe"} />
                </div>
                <div className='why-invest-in-mysore-card-title'>
                  Water Connectivity
                </div>
              </div>
              <div className='why-invest-in-mysore-card-sub-title'>
                Cauvery water connectivity available in Mysore, rarely see water
                problems in Mysore city
              </div>
            </div>
            <div
              style={{
                width: 300,
                height: 175,
                paddingTop: 10,
                margin: "auto",
                borderBottom: "1px dashed #209541",
              }}>
              <div style={{ display: "flex" }}>
                <div>
                  <img src={bin} alt={"waterPipe"} />
                </div>
                <div className='why-invest-in-mysore-card-title'>
                  Cleanest City
                </div>
              </div>
              <div className='why-invest-in-mysore-card-sub-title'>
                One of the cleanest cities in india. Mysuru was declared as the
                cleanest city for several years.
              </div>
            </div>
            <div
              style={{
                width: 300,
                height: 175,
                paddingTop: 10,
                margin: "auto",
                borderBottom: "1px dashed #209541",
              }}>
              <div style={{ display: "flex" }}>
                <div>
                  <img src={education} />
                </div>
                <div className='why-invest-in-mysore-card-title'>
                  Education Hub
                </div>
              </div>
              <div className='why-invest-in-mysore-card-sub-title'>
                Mysuru is an education hub, with more than 250+ institutions.
              </div>
            </div>
            <div
              style={{
                width: 300,
                height: 175,
                paddingTop: 10,
                margin: "auto",
                borderBottom: "1px dashed #209541",
              }}>
              <div style={{ display: "flex" }}>
                <div>
                  <img src={map} />
                </div>
                <div className='why-invest-in-mysore-card-title'>
                  City Connectivity
                </div>
              </div>
              <div className='why-invest-in-mysore-card-sub-title'>
              Mysore is well-connected to major cities by air, train, and bus.
              </div>
            </div>
            <div
              style={{
                width: 300,
                height: 175,
                paddingTop: 10,
                margin: "auto",
                borderBottom: "1px dashed #209541",
              }}>
              <div style={{ display: "flex" }}>
                <div>
                  <img src={sunnyCloude} />
                </div>
                <div className='why-invest-in-mysore-card-title'>
                  Pleasant Weather
                </div>
              </div>
              <div className='why-invest-in-mysore-card-sub-title'>
                Mysore is the cultural capital of Karnataka and has a welcoming
                environment. Cool Weather most of the time.
              </div>
            </div>
            <div
              style={{
                width: 300,
                height: 175,
                paddingTop: 10,
                margin: "auto",
                borderBottom: "1px dashed #209541",
              }}>
              <div
                style={{
                  display: "flex",
                  // borderBottom: "1px dashed #209541",
                }}>
                <div>
                  <img src={hospital} />
                </div>
                <div className='why-invest-in-mysore-card-title'>Hospitals</div>
              </div>
              <div className='why-invest-in-mysore-card-sub-title'>
                Mysore is having more advanced, multispeciality hospitals. We
                could see very good medical staff.{" "}
              </div>
            </div>
            <div
              style={{
                width: 300,
                height: 175,
                paddingTop: 10,
                margin: "auto",
                borderBottom: "1px dashed #209541",
              }}>
              <div style={{ display: "flex" }}>
                <div>
                  <img src={safe} />
                </div>
                <div className='why-invest-in-mysore-card-title'>Safe City</div>
              </div>
              <div className='why-invest-in-mysore-card-sub-title'>
              Mysore is one of the south Indian city that makes it to the list of safest cities in India..
              </div>
            </div>
            <div
              style={{
                width: 300,
                height: 175,
                paddingTop: 10,
                margin: "auto",
                borderBottom: "1px dashed #209541",
              }}>
              <div style={{ display: "flex" }}>
                <div>
                  <img src={train} />
                </div>
                <div className='why-invest-in-mysore-card-title'>
                  Double Track Train
                </div>
              </div>
              <div className='why-invest-in-mysore-card-sub-title'>
                Double track train faiclity available in Mysore, every 1 hours
                you will get train from Bangalore to Mysore.
              </div>
            </div>
            <div
              style={{
                width: 300,
                height: 175,
                paddingTop: 10,
                margin: "auto",
                borderBottom: "1px dashed #209541",
              }}>
              <div style={{ display: "flex" }}>
                <div>
                  <img src={Flight} />
                </div>
                <div className='why-invest-in-mysore-card-title'>Airport</div>
              </div>
              <div className='why-invest-in-mysore-card-sub-title'>
                Advanced airpot is available in Mysore. Coimbatore international
                airport & Bangalore international airport are around 150 KM from
                Mysore.
              </div>
            </div>
            <div
              style={{
                width: 300,
                height: 175,
                paddingTop: 10,
                margin: "auto",
                borderBottom: "1px dashed #209541",
              }}>
              <div style={{ display: "flex" }}>
                <div>
                  <img src={Road} />
                </div>
                <div className='why-invest-in-mysore-card-title'>
                  Flexible Roads{" "}
                </div>
              </div>
              <div className='why-invest-in-mysore-card-sub-title'>
                We could see more flexible roads in Mysore. Less traffic compare
                to other cities
              </div>
            </div>
          </div>
          <div
            style={{
              flex: 1,

              display: "flex",
              flexDirection: "column",
              // backgroundImage: `url("${personBG}")`,
              // // backgroundSize: "cover",
              // backgroundPosition: "left bottom",
            }}>
            <div style={{ flex: 1 }}></div>
            <img
              src={personBG}
              style={{}}
              alt={"bg"}
              className='why-invest-in-mysure-person-image'
            />
          </div>
          <div style={{ flex: 2 }} className='why-invest-points-container-larg'>
            <div
              style={{ display: "flex", borderBottom: "1px dashed #209541" }}>
              <div style={{ width: 300, paddingTop: 10 }}>
                <div style={{ display: "flex" }}>
                  <div>
                    <img src={waterPipe} alt={"waterPipe"} />
                  </div>
                  <div className='why-invest-in-mysore-card-title'>
                    Water Connectivity
                  </div>
                </div>
                <div className='why-invest-in-mysore-card-sub-title'>
                  Cauvery water connectivity available in Mysore, rarely see
                  water problems in Mysore city
                </div>
              </div>
              <div
                style={{
                  width: 351,
                  height: 175,
                  paddingTop: 10,
                  borderLeft: "1px dashed #209541",
                  paddingLeft: 20,
                }}>
                <div style={{ display: "flex" }}>
                  <div>
                    <img src={bin} alt={"waterPipe"} />
                  </div>
                  <div className='why-invest-in-mysore-card-title'>
                    Cleanest City
                  </div>
                </div>

                <div className='why-invest-in-mysore-card-sub-title'>
                  One of the cleanest cities in india. Mysuru was declared as
                  the cleanest city for several years.
                </div>
              </div>
            </div>
            <div
              style={{ display: "flex", borderBottom: "1px dashed #209541" }}>
              <div style={{ width: 300, height: 44, paddingTop: 10 }}>
                <div style={{ display: "flex" }}>
                  <div>
                    <img src={education} />
                  </div>
                  <div className='why-invest-in-mysore-card-title'>
                    Education Hub
                  </div>
                </div>
                <div className='why-invest-in-mysore-card-sub-title'>
                  Mysuru is an education hub, with more than 250+ institutions.
                </div>
              </div>
              <div
                style={{
                  width: 351,
                  height: 175,
                  paddingTop: 10,
                  borderLeft: "1px dashed #209541",
                  paddingLeft: 20,
                }}>
                <div style={{ display: "flex" }}>
                  <div>
                    <img src={map} />
                  </div>
                  <div className='why-invest-in-mysore-card-title'>
                    City Connectivity
                  </div>
                </div>

                <div className='why-invest-in-mysore-card-sub-title'>
                Mysore is well-connected to major cities by air, train, and bus.
                </div>
              </div>
            </div>

            <div
              style={{ display: "flex", borderBottom: "1px dashed #209541" }}>
              <div style={{ width: 300, height: 44, paddingTop: 10 }}>
                <div style={{ display: "flex" }}>
                  <div>
                    <img src={sunnyCloude} />
                  </div>
                  <div className='why-invest-in-mysore-card-title'>
                    Pleasant Weather
                  </div>
                </div>
                <div className='why-invest-in-mysore-card-sub-title'>
                  Mysore is the cultural capital of Karnataka and has a
                  welcoming environment. Cool Weather most of the time.
                </div>
              </div>
              <div
                style={{
                  width: 351,
                  height: 175,
                  paddingTop: 10,
                  borderLeft: "1px dashed #209541",
                  paddingLeft: 20,
                }}>
                <div
                  style={{
                    display: "flex",
                    // borderBottom: "1px dashed #209541",
                  }}>
                  <div>
                    <img src={hospital} />
                  </div>
                  <div className='why-invest-in-mysore-card-title'>
                    Hospitals
                  </div>
                </div>
                <div className='why-invest-in-mysore-card-sub-title'>
                  Mysore is having more advanced, multispeciality hospitals. We
                  could see very good medical staff.
                </div>
              </div>
            </div>
            <div
              style={{ display: "flex", borderBottom: "1px dashed #209541" }}>
              <div style={{ width: 300, height: 44, paddingTop: 10 }}>
                <div style={{ display: "flex" }}>
                  <div>
                    <img src={safe} />
                  </div>
                  <div className='why-invest-in-mysore-card-title'>
                    Safe City
                  </div>
                </div>
                <div className='why-invest-in-mysore-card-sub-title'>
                Mysore is one of the south Indian city that makes it to the list of safest cities in India.
                </div>
              </div>
              <div
                style={{
                  width: 351,
                  height: 175,
                  paddingTop: 10,
                  borderLeft: "1px dashed #209541",
                  paddingLeft: 20,
                }}>
                <div style={{ display: "flex" }}>
                  <div>
                    <img src={train} />
                  </div>
                  <div className='why-invest-in-mysore-card-title'>
                    Double Track Train
                  </div>
                </div>

                <div className='why-invest-in-mysore-card-sub-title'>
                  Double track train faiclity available in Mysore, every 1 hours
                  you will get train from Bangalore to Mysore.
                </div>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ width: 300, height: 44, paddingTop: 10 }}>
                <div style={{ display: "flex" }}>
                  <div>
                    <img src={Flight} />
                  </div>
                  <div className='why-invest-in-mysore-card-title'>Airport</div>
                </div>
                <div className='why-invest-in-mysore-card-sub-title'>
                  Advanced airpot is available in Mysore. Coimbatore
                  international airport & Bangalore international airport are
                  around 150 KM from Mysore.{" "}
                </div>
              </div>
              <div
                style={{
                  width: 351,
                  height: 175,
                  paddingTop: 10,
                  borderLeft: "1px dashed #209541",
                  paddingLeft: 20,
                }}>
                <div style={{ display: "flex" }}>
                  <div>
                    <img src={Road} />
                  </div>
                  <div className='why-invest-in-mysore-card-title'>
                    Flexible Roads
                  </div>
                </div>
                <div className='why-invest-in-mysore-card-sub-title'>
                  We could see more flexible roads in Mysore. Less traffic
                  compare to other cities
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageSection>
    </div>
  );
};
export default WhyInvestInMysuru;
