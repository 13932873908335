import React, { useEffect, useState } from "react";

import {
  createBrowserRouter,
  RouterProvider,
  useParams,
  useLocation,
} from "react-router-dom";

import "./App.css";
import Navbar from "./components/NavBar";
import FooterSection from "./components/FooterSection";
import About from "./components/About";
// import pages
import PropertyDetails from "./Pages/PropertyDetails";
import Blogs from "./Pages/Blogs";
import AllProjectsPage from "./Pages/AllProjectsPage";
import Home from './Pages/Home/index';
import AllFarmlandProjects from './Pages/AllFarmlandProjects/index';

const ProjectRoute = () => {
  let { id } = useParams();
  return (
    <div className="App" style={{ overflow: "scroll" }}>
      <Navbar />
      <PropertyDetails id={id} />
      <FooterSection />
    </div>
  );
};
const AboutUs = () => {
  return (
    <div className="App" style={{ overflow: "scroll" }}>
      <Navbar />
      <About />
      <FooterSection />
    </div>
  );
};
const Blog = () => {
  return (
    <div className="App" style={{ overflow: "scroll" }}>
      <Navbar />
      <Blogs />
      <FooterSection />
    </div>
  );
};
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/project/:id",
    element: <ProjectRoute />,
  },

  {
    path: "/about-us",
    element: <AboutUs />,
  },
  {
    path: "/blogs",
    element: <Blog />,
  },
  {
    path: "/blogs/:id",
    element: <Blog />,
  },
  {
    path: "/all-projects",
    element: <AllProjectsPage/>,
  },
  {
    path: "/farmland-projects",
    element: <AllFarmlandProjects/>,
  },
]);
const App = () => (
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
export default App;
