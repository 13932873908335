import React, { useRef, useEffect, useState } from "react";
import style from "./css/RecommandationBar.module.css";
import Slider from "react-slick";
import { Link } from 'react-router-dom';

export default function RecommandationBar({ type = "residential", activeId }) {
  const sliderRef = useRef();
  const initialTabData = [{
    title: "GP-SECTOR 1",
    id: "63a1b47972f212666ad7547e"
  },
  {
    title: "GP-SECTOR 2",
    id: "63a1b03a72f212666ad7397d"
  },
  {
    title: "GP-SECTOR 3",
    id: "63a1b10b72f212666ad73ea5"
  },
  {
    
    title: "GP-SECTOR 4",
    id: "63a1acb072f212666ad72baa"
  },
  {
    title: "GP-Premium Phase 1",
    id: "63a1a88c72f212666ad72130"
  },
  {
    title: "GP-Premium Phase 2",
    id: "63a1ad8272f212666ad73132"
  },
  {
    title: "GP-Premium Phase 3",
    id: "63a1b09072f212666ad73b1c"
  },
]
  const [tabData, setTabData] = useState(initialTabData)
  //todo: set initial slide the active one
  let settings = {
    arrows: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    // initialSlide: tabData.findIndex(i =>i._id===activeId)!== -1 || 0,
    // infinite: true,
    responsive: [
      {
        breakpoint: 1692,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        //   infinite: true,
        },
      },
      {
        breakpoint: 1360,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        //   infinite: true,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        //   infinite: true,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        //   infinite: true,
        },
      },
    ],
  };

  return (
    <div className={style.container}>
      <div className={style.wrapper}> 
        <Slider {...settings} ref={sliderRef}>
          {tabData.map((item) => {
            // return <RecommandationCard title={item.title.split("GOLDENPROPERTY – ").length>1?item.title.split("GOLDENPROPERTY – ")[1]:item.title.split("GOLDENPROPERTY – ")[0]} path={`/project/${item._id}`} selected={activeId===item._id?true:false}/>
            return <RecommandationCard title={item?.title} path={`/project/${item?.id}`} selected={activeId===item?.id?true:false}/>
          })}
        </Slider>
      </div>
      <img
        src="/assets/ProjectsDetailsPage/next.svg"
        alt=""
        className={style.prev_btn}
        onClick={() => sliderRef.current.slickPrev()}
      />
      <img
        src="/assets/ProjectsDetailsPage/next.svg"
        alt=""
        className={style.next_btn}
        onClick={() => sliderRef.current.slickNext()}
      />
    </div>
  );
}

export function RecommandationCard({ title, selected = false, path="/" }) {
  return (
    <Link to={path}>
    <div
      className={style.card_container}
      style={{
        background: selected ? "#209541" : "#fff",
        color: selected ? "#F8FFF9" : "rgba(11, 11, 18, 0.9)",
        border: selected ? "none" : "0.5px solid #BABABA",
      }}>{title}</div>
    </Link>
  );
}
