import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ShareIcon from "../../assets/images/Blogs/ShareIcons.svg";
import {
  LikeOutlined,
  LikeFilled,
  DislikeOutlined,
  DislikeFilled,
} from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { Modal } from "antd";
import ShareModal from "../../components/ShareModal";
import { blogLike } from "../../api/golednProperty";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./index.css";
import LoginModal from "../../components/LoginModal";
import BlogWithComment from "./BlogWithComment";

const Conatiner = styled.div`
  width: 100%;
  // margin-bottom: 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;
const BlogImage = styled.img`
  width: 100%;
  height: 443px;
  object-fit: cover;
  @media screen and (max-width: 768px) {
    height: auto;
  }
`;
const Tittle = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 27px;
  width: 100%;
  color: #121212;
  @media (max-width: 778px) {
    font-size: 18px;
  }
`;
const TotleDiv = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: space-between;
`;
const ImgageDiv = styled.div`
  width: 100%;
`;
const LikeDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 3%;
  width: 100%;
`;
const AmountSpan = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  margin-left: 3%;

  color: rgba(0, 0, 0, 0.5);
`;
const Liked = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const ContentDiv = styled.div`
  width: 100%;
  padding: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  color: rgba(18, 18, 18, 0.7);
  @media (max-width: 778px) {
    font-size: 14px;
  }
`;
const ReadMoreDiv = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #209541;
  margin-left: 1.5%;
`;
const ShareDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  cursor: pointer;
`;
const StyledReactMarkDown = styled(ReactQuill)`
  font-style: normal;
  font-weight: 400;
  font-size: 20px !important;
  line-height: 22px;
  text-align: justify;
  letter-spacing: -0.1px;
  color: rgba(11, 11, 18, 0.87);
  padding: none !important;
`;
const StyledLoginModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 6px !important;
  }
`;

const BlogCard = ({ cardDetails, handleRefresh }) => {
  const [isModal, setModal] = useState(false);
  const [blogId, setBlogId] = useState("");
  const locations = useLocation();
  const [token, setToken] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [loginModal, setLoginModal] = useState(false);

  const url = `${process.env.REACT_APP_URL + locations.pathname}`;

  useEffect(() => {
    setToken(localStorage.getItem("access_token"));
    setName(localStorage.getItem("name"));

    setEmail(localStorage.getItem("email"));
  });

  const handleShare = (id) => {
    setModal(true);
    setBlogId(id);
  };

  const handleOk = () => {
    setModal(false);
  };

  const handleCancel = () => {
    setModal(false);
  };
  const handleLike = (id, dataindex) => {
    let data = {
      email: email,
      name: name,
      likeDislike: "like",
    };

    if (token && email) {
      blogLike(data, id).then((res, rej) => {
        if (res.status === 200) {
          // message.success("Liked this blogs");
        }
        setBlogId(res.data);
        handleRefresh();
      });
    } else {
      setLoginModal(true);
    }
  };
  const handleDisLike = (index) => {
    let data = {
      email: email,
      name: name,
      likeDislike: "dislike",
    };

    if (token && email) {
      blogLike(data, index).then((res, rej) => {
        if (res.status === 200) {
          // message.success("disliked this blogs");
          handleRefresh();
        }
      });
    } else {
      setLoginModal(true);
    }
  };

  function cancelLoginModal() {
    setLoginModal(false);
  }
  const text =
    "Bangalore has always been a potential Residential Property investment destination with the hub of IT companies in the city. Due to its vast culture, the city attracts people from all over the nation. Bangalore alone has a total of 40% of shares in the Indian IT sector which emerges itself into one of the fastest developing metro cities in India. As the population in Bangalore is rising there has been a shift in the demand for houses as well as plots for investment purpose.";
  return (
    <>
      {cardDetails.map((eachValue, index) => (
        <div style={{ boxShadow: "1px solid rgba(0,0,0,0.1)" }}>
          <BlogWithComment
            eachValue={eachValue}
            token={token}
            name={name}
            email={email}
            index={index}
            handleShare={handleShare}
            handleLike={handleLike}
            handleDisLike={handleDisLike}
            setLoginModal={setLoginModal}
          />
        </div>
      ))}
      <Modal
        title="Share with Friends and Family"
        open={isModal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <ShareModal url={url} id={blogId} />
      </Modal>
      <StyledLoginModal
        title={null}
        open={loginModal}
        onCancel={cancelLoginModal}
        centered
        footer={null}
      >
        <LoginModal handleModal={cancelLoginModal} />
      </StyledLoginModal>
    </>
  );
};
export default BlogCard;

export function BlogItem({
  eachValue,
  token,
  name,
  email,
  index,
  handleShare,
  handleLike,
  handleDisLike,
  toggleComment,
}) {
  const [isLiked, setIsLiked] = useState(false);
  const [isDisLiked, setIsDisLiked] = useState(false);
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <Conatiner id={eachValue._id}>
      <ImgageDiv>
        <BlogImage src={eachValue.image} alt="blog-images" />
      </ImgageDiv>
      <TotleDiv>
        <Tittle>{eachValue.title}</Tittle>
        <LikeDiv>
          <Liked
            onClick={() => handleLike(eachValue._id, index)}
            style={{ cursor: "pointer" }}
          >
            {eachValue.isLiked === false ? (
              <LikeOutlined style={{ fontSize: "24px" }} />
            ) : (
              <LikeFilled style={{ color: "green", fontSize: "24px" }} />
            )}
            <AmountSpan>{eachValue.likes}</AmountSpan>
          </Liked>
          <Liked
            onClick={() => handleDisLike(eachValue._id)}
            style={{ cursor: "pointer" }}
          >
            {eachValue.isLiked === true ? (
              <DislikeOutlined style={{ fontSize: "24px" }} />
            ) : (
              <DislikeFilled style={{ color: "green", fontSize: "24px" }} />
            )}
            <AmountSpan>{eachValue.dislikes}</AmountSpan>
          </Liked>
          <div style={{ cursor: "pointer" }} onClick={() => toggleComment()}>
            <img src="/assets/comments/comment.svg" />
          </div>
          <ShareDiv
            onClick={() => {
              handleShare(eachValue._id);
            }}
          >
            <img src={ShareIcon} alt="share" />
          </ShareDiv>
        </LikeDiv>
      </TotleDiv>
      <ContentDiv>
        {/* {isReadMore
              ? eachValue?.description.slice(0, 250)
              : eachValue?.description} */}
        <StyledReactMarkDown
          value={
            isReadMore
              ? eachValue?.description.slice(0, 250)
              : eachValue?.description
          }
          theme={"bubble"}
          readOnly
        />
        {eachValue?.description.length > 250 && (
          <ReadMoreDiv onClick={toggleReadMore}>
            {isReadMore ? "Read More" : " Read Less"}
          </ReadMoreDiv>
        )}
      </ContentDiv>
    </Conatiner>
  );
}
