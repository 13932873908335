import React, { useState } from "react";
import { projectDropDown } from "./navProjectItems";
import { Link } from "react-router-dom";
import "./Dropdown.css";

function DropdownProjects({ updateProjectFilter }) {
  const [dropdown, setDropdown] = useState(false);
  function updatefilterHandler(value) {
    if (value === "Residential Plot") {
      updateProjectFilter("residential")
      setDropdown(false)
    }
    if (value === "Farm Land") {
      updateProjectFilter("farmland")
      setDropdown(false)
    }
  }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", backgroundColor: "#ffffff" }}>
        <div className="maindiv">
          <ul
            className={dropdown ? "projectDropMenu clicked" : "nav-items"}
            onClick={() => setDropdown(p => !p)}
          >
            {projectDropDown.map((item) => {
              return (
                <li key={`item-${item?.id}`} onClick={() => updatefilterHandler(item?.title)}>
                  <Link className={item?.cName} key={`link-${item?.id}`} to={item?.path}>
                    {item?.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}

export default DropdownProjects;
