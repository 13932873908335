import request from "../request";

export const amenitiesData = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await request.get(`/api/amenities`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      if (response.status === 200) {
        resolve(response);
      } else {
        reject(response);
      }
    } catch (error) {
      reject(error);
    }
  });
};
export const blogsData = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await request.get(`/api/blogs`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      if (response.status === 200) {
        resolve(response);
      } else {
        reject(response);
      }
    } catch (error) {
      reject(error);
    }
  });
};
export const upcomingProjectData = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await request.get(`/api/upcoming-projects`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      if (response.status === 200) {
        resolve(response);
      } else {
        reject(response);
      }
    } catch (error) {
      reject(error);
    }
  });
};
export const trendingProjectData = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await request.get(`/api/trending-projects`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      if (response.status === 200) {
        resolve(response);
      } else {
        reject(response);
      }
    } catch (error) {
      reject(error);
    }
  });
};
export const bannerImages = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await request.get(`/api/banners`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      if (response.status === 200) {
        resolve(response);
      } else {
        reject(response);
      }
    } catch (error) {
      reject(error);
    }
  });
};
export const testimonials = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await request.get(`/api/testimonials/read`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      if (response.status === 200) {
        resolve(response);
      } else {
        reject(response);
      }
    } catch (error) {
      reject(error);
    }
  });
};
export const AwardsData = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await request.get(`/api/awards`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      if (response.status === 200) {
        resolve(response);
      } else {
        reject(response);
      }
    } catch (error) {
      reject(error);
    }
  });
};
export const propertyData = (type) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await request.get(
        `/api/properties?type=${type}
      `,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.status === 200) {
        resolve(response);
      } else {
        reject(response);
      }
    } catch (error) {
      reject(error);
    }
  });
};
export const propertyDetailsData = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await request.get(`/api/properties/${id}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      if (response.status === 200) {
        resolve(response);
      } else {
        reject(response);
      }
    } catch (error) {
      reject(error);
    }
  });
};
export const propertyDetailsTrendingData = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await request.get(`/api/trending-projects/${id}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      if (response.status === 200) {
        resolve(response);
      } else {
        reject(response);
      }
    } catch (error) {
      reject(error);
    }
  });
};
export const otherProjectData = (type, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await request.get(
        `/api/properties?type=${type}&view=${id}
      `,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.status === 200) {
        resolve(response);
      } else {
        reject(response);
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const contactUS = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await request.post(`/api/contacts`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        resolve(response);
      } else {
        reject(response);
      }
    } catch (error) {
      reject(error);
    }
  });
};
export const blogLike = (data, id) => {
  let token = localStorage.getItem("access_token");

  return new Promise(async (resolve, reject) => {
    try {
      const response = await request.post(
        `api/blogs/like-dislike/${id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${localStorage.getItem("access_token")}`,
          },
        }
      );
      if (response.status === 200) {
        resolve(response);
      } else {
        reject(response);
      }
    } catch (error) {
      reject(error);
    }
  });
};
export const blogDataLike = (data, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await request.get(`api/blogs`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        resolve(response);
      } else {
        reject(response);
      }
    } catch (error) {
      reject(error);
    }
  });
};
