import React, { useState, useEffect, useRef, useMemo } from "react";
import "./css/index.css";
import Slider from "react-slick";
import ourProjectsStyles from "./css/index.module.css";
import projectCardStyle from "./css/ProjectCard.module.css";
//import icons
import ResidentialPlotsGreen from "../../assets/appLogos/ResidentialPlotsGreen.svg";
import ResidentialPlotsGray from "../../assets/appLogos/ResidentialPlotsGray.svg";
import FarmLandGray from "../../assets/appLogos/FarmLandGray.svg";
import FarmLandGreen from "../../assets/appLogos/FarmLandGreen.svg";
import { Link } from "react-router-dom";
//apis
import { propertyData } from "../../api/golednProperty";

export default function OurProjects({
  filter = "farmland",
  updateProjectFilter,
}) {
  const residentialData = [{
    _id: "63a1b47972f212666ad7547e",
    image: ["/assets/ProjectUnderLogo/residential/golden-prop.png"],
    logo: "/assets/ProjectUnderLogo/GOLDENPROPERTY.png",
    title: "GOLDENPROPERTY",
    description: `GOLDENPROPERTY CASTLE  a concern of CANH Infrastructure 
  Ltd., is a project catering to the needs of common man. It offers
  Residential plots at Mysuru – Hunsur NH 275  near 
  Manuganahalli Checkpost. A destination that is   favourite 
  among residents of Musuru and surrounding areas .This is a 
  project to match your lifestyle requirements. We have  been 
  serving clients for a number of years.`,
  },];
  
  const [farmlandData, setFarmlandData] = useState([]);
  let settings = {
    // dots: true,
    arrows: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: filter === "residential" ? 1 : 3,
    slidesToScroll: filter === "residential" ? 1 : 2,
    initialSlide: 0,
    infinite: true,
    responsive: [
      {
        breakpoint: 1392,
        settings: {
          slidesToShow: filter === "residential" ? 1 : 3,
          slidesToScroll: filter === "residential" ? 1 : 3,
          infinite: true,
          //dots: true,
        },
      },
      {
        breakpoint: 1060,
        settings: {
          slidesToShow: filter === "residential" ? 1 : 2,
          slidesToScroll: filter === "residential" ? 1 : 2,
          // initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  const sliderRef = useRef();
  useEffect(() => {
    propertyData("farmland").then((res, rej) => {
      if (res.status === 200) {
        setFarmlandData(res?.data?.properties);
      } else {
        console.log(rej, "this is rej");
      }
    });
  }, []);

  const residentialCards = useMemo(() => {
    return residentialData.map((item, index) => {
      return (
        <div className="our-projects__card-wrapper">
        <ProjectCard
          image={item?.image[0]}
          logo={"/assets/ProjectUnderLogo/GOLDENPROPERTY.png"}
          title={item?.title}
          description={item?.description}
          link={`/project/${item?._id}`}
          key={`residential${index}`}
          width="100%"
          />
          </div>
      );
    });
  }, [residentialData, filter]);

  const farmlandCards = useMemo(() => {
    return farmlandData.map((item, index) => {
      return (
        <div className="our-projects__card-wrapper">
          <ProjectCard
            image={item?.image.length >= 1 ? item?.image[1] : item?.image[0]}
            logo={item?.logo}
            title={item?.title}
            description={item?.description}
            link={`/project/${item?._id}`}
            key={`farmland${index}`}
            width="100%"
            />
          </div>
      );
    });
  }, [farmlandData, filter]);

  return (
    <>
      <div className={ourProjectsStyles.container} id="residential-section">
        <h1 className={ourProjectsStyles.main_title}>Our Projects</h1>
        <p className={ourProjectsStyles.main_subtitle}>
          The Noteworthy Real Estate in India
        </p>

        <div className={ourProjectsStyles.filter}>
          <div
            className={ourProjectsStyles.filter_item}
            onClick={() => updateProjectFilter("farmland")}
            style={{
              background:
                filter === "farmland" ? "rgba(37, 160, 71, 0.19)" : "#EEEEEE",
              color: filter === "farmland" ? "#209541" : "#090909",
              border: filter === "farmland" ? "1px solid #209541" : "none",
            }}
          >
            <img
              src={filter === "farmland" ? FarmLandGreen : FarmLandGray}
              className={ourProjectsStyles.filter_item_icon}
            />
            <div className={ourProjectsStyles.filter_item_name}>FARM LAND</div>
          </div>
          <div
            className={ourProjectsStyles.filter_item}
            onClick={() => updateProjectFilter("residential")}
            style={{
              background:
                filter === "residential"
                  ? "rgba(37, 160, 71, 0.19)"
                  : "#EEEEEE",
              color: filter === "residential" ? "#209541" : "#090909",
              border: filter === "farmland" ? "none" : "1px solid #209541",
            }}
          >
            <img
              src={
                filter === "residential"
                  ? ResidentialPlotsGreen
                  : ResidentialPlotsGray
              }
              className={ourProjectsStyles.filter_item_icon}
            />
            <div className={ourProjectsStyles.filter_item_name}>
              RESIDENTIAL PLOTS
            </div>
          </div>
        </div>
        <div className={ourProjectsStyles.projects_container}>
          <Slider {...settings} ref={sliderRef}>
            {filter === "residential" ? residentialCards : farmlandCards}
          </Slider>
          {filter !== "residential" && (
            <>
              <img
                src="/assets/Slider/button.svg"
                alt="preview button"
                className={ourProjectsStyles.prev_btn}
                onClick={() => sliderRef.current.slickPrev()}
              />
              <img
                src="/assets/Slider/button.svg"
                alt="next button"
                className={ourProjectsStyles.next_btn}
                onClick={() => sliderRef.current.slickNext()}
              />
            </>
          )}
        </div>
        {filter === "farmland" && <Link to={"/farmland-projects"} className={ourProjectsStyles.see_more_btn}>
          SEE MORE
        </Link>}
      </div>
    </>
  );
}

export function ProjectCard({ image, logo, title, description = "", link, width }) {
  return (
    <Link to={link}>
      <div className={projectCardStyle.container} style={{width:width?width:408}}>
        <div className={projectCardStyle.main_image_container}>
          <img src={image} alt={title} />
        </div>
        <div className={projectCardStyle.logo_container}>
          <img loading="lazy" srcset={logo} alt={title} />
        </div>
        <h2 className={projectCardStyle.title}>{title}</h2>
        <p className={projectCardStyle.description}>
          {`${description.slice(0, 141)}${
            description.length > 140 ? "..." : ""
          }`}
        </p>
        <div className={projectCardStyle.calltoaction_conatiner}>
          <div to={link} className={projectCardStyle.link_btn}>
            Know More
          </div>
        </div>
      </div>
    </Link>
  );
}
