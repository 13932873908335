import React, { useState, useEffect } from "react";
import Style from "./css/index.module.css";
import Navbar from "../../components/NavBar/index";
import FooterSection from "../../components/FooterSection/index";
import { ProjectCard } from "../../components/OurProjects/index";
import { propertyData } from "../../api/golednProperty";
import ContactWithUsPopup from "../../components/ContactWithUsPopup/index";


export default function AllFarmlandProjects() {
  const [projectData, setProjectData] = useState([]);
  useEffect(() => {
    propertyData("farmland").then((res, rej) => {
      if (res.status === 200) {
        setProjectData(res?.data?.properties);
      } else {
        console.log(rej, "this is rej");
      }
    });
  }, []);

  return (
    <>
      <ContactWithUsPopup infinite={false}/>
      <div>
        <Navbar />
        <div className={Style.container}>
          {projectData.map((item) => {
            if (item.title.toUpperCase()==="GOLDENPROPERTY") {
              item.logo = "/assets/ProjectUnderLogo/GOLDENPROPERTY.png";
            }
            return (
              <div className={Style.item_wrapper}>
                <ProjectCard
                  image={item?.image[0]}
                  logo={item?.logo}
                  title={item?.title}
                  description={item?.description}
                  link={`/project/${item?._id}`}
                  width="100%"
                />
              </div>
            );
          })}
        </div>
        <FooterSection />
      </div>
    </>
  );
}
