import "./about.css";
import { Helmet } from "react-helmet";
import { useRef } from "react";
import numone from "./Assets/numone.png";
import numtwo from "./Assets/numtwo.png";
import numthree from "./Assets/numthree.png";
import ConnectForm from "../ConnectForm/ConnectForm";

const GoldenProp = ({
  title,
  tagline,
  title2,
  tagline2,
  title3,
  img,
  heading,
  subheading,
  title4,
  tagline4,
}) => {
  const movingCarRef = useRef();
  const imageHoverHandler = () => {
    movingCarRef.current.src = "/assets/ContactWithUs/kmp-moving.gif";
  }
  const imageHoverOutHandler = () => {
    movingCarRef.current.src = "/assets/ContactWithUs/kmp-nonmoving.png";
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`About Us | CANH INFRA`}</title>
        <meta name="description" content="About Us"></meta>
        <link rel="canonical" href={`https://canhinfra.com/about-us`} />
      </Helmet>
      <div style={{ background: "#FBFFF5" }}>
        <div
          className='about-container'
          style={{
            display: "flex",
            maxWidth: 1440,
            margin: "auto",
          }}>
          <div className='about-title'>{title}</div>
          <div className='about-tagline'>{tagline}</div>
          <div className='about-title2'>{title2}</div>
          <div className='about-tagline2'>{tagline2}</div>
          <div className='about-title3'>{title3}</div>

          <div className='why-invest-container'>
            <div className='text-with-icon-contsiner'>
              {/* <div className="icon"></div> */}
              <div className='text-container'>
                <div className='heading'>{heading}</div>
                <div className='subheadding'>{subheading}</div>
              </div>
            </div>
          </div>

          <div className='about-title4'>{title4}</div>
          <div className='about-tagline4'>{tagline4}</div>

          <GoldenProps
            title='Overview'
            tagline='CANH Infrastructure Ltd is a project catering to the needs of common man. It offers Residential plots at Mysuru – Hunsur NH 275  near Manuganahalli Checkpost. A destination that is   favourite among residents of Musuru and surrounding areas .This is a project to match your lifestyle requirements. We have  been serving clients for a number of years. Property rates are booming and there can be no better time to invest your hard earned money in profitable deals. Our professionals keep a close watch on the property market and the value of residential properties in different cities and towns of the state.  Whenever you buy Land on the outskirts or in a developing colony , its price is expected to appreciate just as soon as construction activity begins. Historically Land prices in  India have risen upwards and given handsome returns to Investors and genuine buyers alike. One may sell at a profit or stay invested and build a house for your family. Our Experienced Planners on board will ensure that this project is future ready with smart design and efficient layout. Keeping your way of life in mind, this is a Project that gets you as close as you can get!'
            title2='Why Invest with CANH Infrastructure?'
            tagline2='CANH Infrastructure Ltd., we understand the significance of our customer’s needs and strive to serve it.'
            title3='‘Customer is the king’ is our mantra.'
            title4='Features and Amenities'
            tagline4='Residential plots will be allotted on seniority basis in accordance to Government approvals – DTCP & RERA chosen from our land bank in Boochalli, Bettadooru, Devarahalli, Hullenahalli, Manuganahalli, Hallada Kallhalli, Halebeed kaval, situated in Bilikere Hobli.
        '
            heading='Transparency'
            subheading='At CANH Infrastructure Ltd., we PROMISE that utmost transparency in our processes and offer best practices driven by our ethos.'
            heading2='Top Notch Services'
            subheading2='At CANH Infrastructure Ltd., we are COMMITTED to provide superlative service to our customers to make it a hassle-free experience.'
            heading3='Satisfaction'
            subheading3='At CANH Infrastructure Ltd., we offer the best market pricing and believe this is the ideal way of expressing our gratitude.'
          />

          <div
            style={{
              flex: 1,
              paddingTop: 52,
            }}>
            <div
              className='connect-with-us-content'
              style={{
                margin: 10,
                marginLeft: 0,
                maxWidth: 417,
              }}>
              <div
                style={{
                  maxWidth: 417,
                  border: "1px solid #ccc",
                  borderRadius: 5,
                  overflow: "hidden",
                  boxShadow: "0px 2px 20px rgba(67, 81, 1, 0.22)",
                }}>
                <div className='about-connect-info-form-containers'>
                  <div className='about-connect-info-header'>Connect with us</div>
                  <ConnectForm showLetTalk={true} />
                  <div className='car-image'>
                    <img src={"/assets/ContactWithUs/kmp-nonmoving.png"} alt='car' className='cantactus-car-image' ref={movingCarRef} onMouseOver={imageHoverHandler} onMouseOut={imageHoverOutHandler} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default GoldenProp;

const GoldenProps = ({
  title,
  tagline,
  title2,
  tagline2,
  title3,
  img,
  heading,
  subheading,
  heading2,
  subheading2,
  heading3,
  subheading3,
  title4,
  tagline4,
}) => {
  return (
    <div
      className='about-title'
      style={{
        color: "#121212",
        fontWeight: 600,
        fontSize: 28,
        //fontFamily: "sans-serif",
        marginTop: 50,
        marginLeft: 70,
      }}>
      {title}

      <div
        className='about-taglines'
        style={{
          fontWeight: 400,
          fontSize: 16,
          marginTop: 20,
          lineHeight: "24px",
          color: "rgba(18, 18, 18, 0.7)",
          // border: "1px solid red",
          maxWidth: 807,
          marginRight: "25px",
          marginBottom: 10,
        }}>
        {" "}
        {tagline}
      </div>

      <div
        className='about-title3'
        style={{
          fontWeight: 600,
          fontSize: 28,
          color: "#121212",
          marginTop: 50,
        }}>
        {title2}
      </div>
      <div
        className='about-tagline2'
        style={{
          fontWeight: 400,
          fontSize: 16,
          marginTop: 20,
          lineHeight: "24px",
          color: "rgba(18, 18, 18, 0.7)",
          maxWidth: 807,
        }}>
        {" "}
        {tagline2}
      </div>

      <div
        className='about-tagline3'
        style={{
          color: "#209541",
          fontWeight: 600,
          fontSize: 20,
          marginTop: 10,
        }}>
        {title3}
      </div>

      <div
        className='text-with-icon-contsiner'
        style={{
          marginTop: 50,
          display: "flex",
        }}>
        <div
          className='icon'
        >
          <div
            style={{
              marginTop: 10,
              alignItems: "center",
            }}>
            <img src={numone} alt='' className='about_image' />
          </div>
        </div>
        <div>
          <div className='about-heading'>{heading}</div>

          <div
            className='satisfaction-tagline'
            style={{
              marginTop: 10,
              width: 479,
              height: 52,
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
              color: "#212325B2",
              marginBottom: 20,
              marginLeft: 20,
            }}>
            {subheading}
          </div>
        </div>
      </div>

      <div
        className='text-with-icon-contsiner'
        style={{
          marginTop: 50,
          display: "flex",
        }}>
        <div className='icon'>
          <div
            style={{
              marginTop: 10,
              alignItems: "center",
            }}>
            <img src={numtwo} alt='' className='about_image' />
          </div>
        </div>
        <div className='about-heading'>
          {heading2}

          <div
            className='satisfaction-tagline'
            style={{
              marginTop: 10,
              width: 479,
              height: 52,
              lineHeight: "24px",
              fontWeight: 400,
              fontSize: 16,
              color: "#212325B2",
              marginBottom: 20,
            }}>
            {subheading2}
          </div>
        </div>
      </div>

      <div
        className='text-with-icon-contsiner'
        style={{
          marginTop: 50,
          display: "flex",
        }}>
        <div
          className='icon'
        >
          <div
            style={{
              marginTop: 10,
              alignItems: "center",
            }}>
            <img src={numthree} alt='' className='about_image' />
          </div>
        </div>
        <div className='about-heading'>
          {heading3}

          <div
            className='satisfaction-tagline'
            style={{
              marginTop: 10,
              width: 479,
              height: 52,
              lineHeight: "24px",
              fontWeight: 400,
              fontSize: 16,
              color: "#212325B2",
              marginBottom: 20,
            }}>
            {subheading3}
          </div>
        </div>
      </div>
    </div>
  );
};
