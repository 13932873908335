import "./index.css";
import React, { useState, useEffect } from "react";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import DropdownMobileRespo from './DropdownMobileRespo';

import {
  Drawer,
  Modal,
} from "antd";
import "antd/dist/antd.css";
import logo from "../../assets/appLogos/main_logo.svg";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import LoginModal from "../LoginModal";
import { DownOutlined } from "@ant-design/icons";
import DropdownProjects from "./DropdownProjects";

const NavItems = styled.div`
  margin-left: 2.5vw;
  cursor: pointer;
  color: ${(props) => (props.active ? "#16864A" : "  #212325")};
  // opacity: ${(props) => (props.active ? 1 : 0.5)};
  text-align: ${(props) => (props.center && "center") || "left"};
  padding-bottom: ${(props) => (props.center && "0") || "0.45em"};
  text-transform: uppercase;
  transition: 0.5s;
  width: fit-content;
  // font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  &:after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 60%;
    padding-top: 0.45em;
  }
  &:hover {
    color: ${"#16864A"};
  }
  @media (min-width: 1600px) and (max-width: 2500px) {
    // font-family: "Roboto" !important;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
  }
`;
const MenuIcon = styled(MenuOutlined)`
  font-size: 22px;
`;
const StyledLoginModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 6px !important;
  }
`;

const BetaVersionTag = styled.p`
  color: red;
  font-size: 12px;
  line-height: 8;
  margin-left: 100px;
  font-weight: bold;
  white-space: nowrap;

  @media (min-width: 1281px) and (max-width: 1357px) {
    margin-left: 40px;
  }

  @media (min-width: 985px) and (max-width: 1043px) {
    margin-left: 50px;
  }
  @media (min-width: 952px) and (max-width: 984px) {
    margin-left: 20px;
  }
  @media (min-width: 900px) and (max-width: 951px) {
    font-size: 8px;
    margin-left: 5px;
    line-height: 12;
  }
  @media (max-width: 700px) {
    display: none;
  }
`;

const Drop = styled(DropdownProjects)`
background-color:#fff !important;
opacity:none;

&:hover {
  color: ${"#16864A"};
}
}
`;


const Index = ({ updateProjectFilter = (parm) => parm }) => {
  const [showDrawer, setDrawer] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [dropdownNew, setDropdownNew] = useState(false);
  const [token, setToken] = useState();
  const location = useLocation();
  const handleDrawer = (value) => {
    setDrawer(value);
  };
  useEffect(() => {
    setToken(localStorage.getItem("access_token"));
  }, []);
  function cancelLoginModal() {
    setLoginModal(false);
  }

  return (
    <>
      <div className="header-container">
        <div
          className="header-container-content"
        >
          <div className="header-primary-container">
            <HeaderLogo />
            {/* <BetaVersionTag>BETA VERSION</BetaVersionTag> */}
            <div style={{ flex: 0.8 }}></div>
            <div className="nav-bar">
              <Link to={`/`} style={{ textDecoration: "none" }}>
                <NavItems active={location.pathname.endsWith("/#home")} >
                  HOME
                </NavItems>
              </Link>
              <Link to="/about-us" style={{ textDecoration: "none" }}>
                <NavItems active={location.pathname.includes("/about-us")}>
                  ABOUT
                </NavItems>
              </Link>

              {/* <a href="#projects" style={{ textDecoration: "none" }}>
              <div className="header-link">PROJECTS</div>
            </a> */}
              <Link
                // to="/#residential-section"
                style={{ textDecoration: "none" }}
              >
                <NavItems
                  // ref={ref}
                  // to="projects"
                  // active={location.pathname.includes("projects")}
                  onMouseEnter={() => setDropdownNew(true)}
                  onMouseLeave={() => setDropdownNew(false)}
                >
                  Projects
                  {dropdownNew && <DownOutlined />}
                  {dropdownNew && (
                    <Drop updateProjectFilter={updateProjectFilter} />
                  )}
                </NavItems>
              </Link>
              <Link to="/blogs" style={{ textDecoration: "none" }}>
                <NavItems active={location.pathname.endsWith("/blogs")}>
                  BLOGS
                </NavItems>
              </Link>
              <Link to={`/#awards`} style={{ textDecoration: "none" }}>
                <NavItems active={location.pathname.endsWith("/#awards")}>
                  AWARDS
                </NavItems>
              </Link>
              <Link to="/#contact" style={{ textDecoration: "none" }}>
                <NavItems active={location.pathname.includes("#contact")}>
                  CONTACT
                </NavItems>
              </Link>
              {token && (<div>
                {/* <LoginButton><img src="/assets/Navbar/user.svg"/></LoginButton>
              <LoginDrowdown>Login</LoginDrowdown> */}
              </div>)}
            </div>
            {/* <div className='login-button'>
              {!token ? (
                <LogInButton onClick={handleLogin}>Login</LogInButton>
              ) : (
                <div onClick={handleLogout}>
                  <Tooltip
                    placement='bottom'
                    title={"Log Out"}
                    overlayInnerStyle={{
                      color: "black",
                      background: "white",
                      cursor: "pointer",
                    }}
                    trigger={["hover", "click"]}>
                    <img
                      src={ProfileButton}
                      onClick={handleLogout}
                      alt='contact'
                    />
                  </Tooltip>
                </div>
              )}
            </div> */}

            <div className="header-ham">
              <MenuIcon onClick={() => handleDrawer(true)} />
            </div>
          </div>
        </div>
      </div>
      <Drawer
        closable={true}
        onClose={() => handleDrawer(false)}
        open={showDrawer}
        placement="right"
        title={null}
        width="230px"
      >
        <div style={{ padding: "0px" }}>
          <Link to="/" style={{ textDecoration: "none" }}>
            <NavItems active={location.pathname.endsWith("/")}>HOME</NavItems>
          </Link>
          <Link to="/about-us" style={{ textDecoration: "none" }}>
            <NavItems active={location.pathname.includes("/about-us")}>
              ABOUT
            </NavItems>
          </Link>
          <Link
            //to={`/#residential-section`}
            style={{ textDecoration: "none" }}
          //onClick={() => setDrawer(false)}
          >
            {/* <div>
            <NavItems
              onClick={(e) =>
                setShowProjectmobileViewSubmenue(!showProjectmobileViewSubmenue)
              }
            >
              PROJECTS
            </NavItems>
          </div> */}
            <DropdownMobileRespo />

            {/* <Dropdown
              menu={{
                items,
              }}
              trigger={["click"]}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                PROJECTS
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown> */}
          </Link>

          <Link to="/blogs" style={{ textDecoration: "none" }}>
            <NavItems>BLOGS</NavItems>
          </Link>
          <Link
            to="/#awards"
            style={{ textDecoration: "none" }}
            onClick={() => setDrawer(false)}
          >
            <NavItems active={location.pathname.endsWith("/#awards")}>
              AWARDS
            </NavItems>
          </Link>
          <Link
            to="/#contact"
            style={{ textDecoration: "none" }}
            onClick={() => setDrawer(false)}
          >
            <NavItems active={location.pathname.includes("/#contact")}>
              CONTACT
            </NavItems>
          </Link>
        </div>
      </Drawer>
      <StyledLoginModal
        title={null}
        open={loginModal}
        onCancel={cancelLoginModal}
        centered
        footer={null}
      >
        <LoginModal handleModal={cancelLoginModal} />
      </StyledLoginModal>
    </>
  );
};

const HeaderLogo = () => {
  return (
    <Link to="/" style={{ display: "flex", alignItem: "center" }}>
      <img src={logo} alt="goldenPropertyLogo" className="main_nav_logo" />
    </Link>
  );
};

export default Index;
