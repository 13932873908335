import React from "react";
import {
  FacebookShareButton,
  WhatsappShareButton,
  InstapaperShareButton,
} from "react-share";
import styled from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { message } from "antd";
import WhatsApp from "./images/Whatsapp.svg";
import Facebook from "./images/Facebook.svg";
import Instagram from "./images/Instagram.svg";
import { LinkOutlined } from "@ant-design/icons";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const SingleOption = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;
  align-items: center;
  gap: 10px;
`;
const IconWrapper = styled.div`
  position: relative;
  height: 33px;
  width: 33px;
  margin-right: 3px;
`;
const MediaName = styled.div`
  text-transform: capitalize;
  font-weight: 400;
  font-size: 14px;
  line-height: 41px;
  color: #121212;
  padding-top: 1%;
`;
const ShareModal = ({ id, url, handleCancel }) => {
  function handleCopy() {
    message.success("Link copied");
    handleCancel();
  }
  return (
    <Container>
      <WhatsappShareButton url={url}>
        <SingleOption>
          <IconWrapper>
            <img src={WhatsApp} alt="fill" />
          </IconWrapper>
          <MediaName>Whatsapp</MediaName>
        </SingleOption>
      </WhatsappShareButton>
      <FacebookShareButton url={url}>
        <SingleOption>
          <IconWrapper>
            <img src={Facebook} alt="fill" />
          </IconWrapper>
          <MediaName>Facebook</MediaName>
        </SingleOption>
      </FacebookShareButton>
      {/* <TwitterShareButton url={url}>
        <SingleOption>
          <IconWrapper>
            <img src={Instagram} alt="fill" />
          </IconWrapper>
          <MediaName>Twitter</MediaName>
        </SingleOption>
      </TwitterShareButton> */}
      <InstapaperShareButton url={url}>
        <SingleOption>
          <IconWrapper>
            <img src={Instagram} alt="fill" />
          </IconWrapper>
          <MediaName>Instagram</MediaName>
        </SingleOption>
      </InstapaperShareButton>
      <CopyToClipboard text={`${url}#${id}`} onCopy={handleCopy}>
        <SingleOption>
          <IconWrapper>
            <LinkOutlined style={{ fontSize: "30px" }} />
          </IconWrapper>
          <MediaName>Copy Link</MediaName>
        </SingleOption>
      </CopyToClipboard>
    </Container>
  );
};
export default ShareModal;
