import React, { useState, useEffect } from "react";
import style from "./css/LocalityTab.module.css";
import gpunitsStyle from "./css/GPUnits.module.css";

export default function LocalityTab({ locality, location }) {
  let arr, arr1;
  const Color = ["#FF4D49", "#D7A800", "#43C14D", "#40A6AC", "#5866B4"];
  const backgroundColor = [
    "#FFF6F5",
    "#FFFAE7",
    "#EDFFEE",
    "#F3FEFF",
    "#F8F9FF",
  ];

  const background = ["#FECCCB", "#FFEDAD", "#C0F3C4", "#C0F0F3", "#C0C8F3"];
  return (
    <div className={style.container} id="ProjectDetailsTabNearThings">
      <div className="where_from">Locality</div>
      {(!locality || locality.length === "null") && (
        <div style={{ textAlign: "center" }}>No details found</div>
      )}
      <div className={style.map_container}>
        <img
          src="/assets/ProjectsDetailsPage/map.png"
          alt=""
          className={style.map_image}
        />
      </div>
      <div className={style.near_locations}>
        {locality &&
          locality.map((d, i) => {
            return (
              <NewGPUnits
                key={i}
                color={Color[i % Color?.length]}
                icon={d?.icon}
                subtitle={d?.localitiesDistance}
                title={d?.localitiesName}
              />
            );
          })}
      </div>
    </div>
  );
}

const NewGPUnits = ({
  subtitle,
  title,
  color,
  backgroundColor,
  background,
}) => {
  const distanceValue = parseFloat(subtitle.split(" ")[0]);
  const distanceType = subtitle.split(" ")[1];
  const [width, setWidth] = useState(30);
  
  useEffect(() => {
    function calculateGPUWidth(maxWidth) {
      if (distanceType === "km") {
        if (distanceValue < 40) {
          const totaldistance = 40;
        const percentage = (distanceValue / totaldistance) * 100;
        const width = (maxWidth / 100) * percentage;
        if (width>=maxWidth) {
          setWidth(maxWidth)
        }else{
          if (width+35>=maxWidth) {
            setWidth(maxWidth)
          } else {
            setWidth(width+35)
          }
        }
        } else {setWidth(maxWidth);}
      } 
      // for time
      if (distanceType !== "km") {
        if (distanceValue < 35) {
          const totalTime = 60;
          const percentage = (distanceValue / totalTime) * 100;
          const width = (maxWidth / 100) * percentage;
          if (width>=maxWidth) {
            setWidth(maxWidth)
          } else {
            if (width+35>=maxWidth) {
              setWidth(maxWidth)
            }else{
              setWidth(width+35)
            }
          }

        } else {
          setWidth(maxWidth);
        }
      }
    }

    if(window.innerWidth <= 1280){
      calculateGPUWidth(48)
    }else{
        calculateGPUWidth(56)
      }
  }, [subtitle])
  

  return (
    <div className={gpunitsStyle.container}>
      <div className={gpunitsStyle.main_wrapper}>
        <div className={gpunitsStyle.slide} style={{ width: `${width}vw` }}>
          <div className={gpunitsStyle.slide_wrapper}>
            <div className={gpunitsStyle.title}>{title}</div>
            <div className={gpunitsStyle.horizontal_line}></div>
          </div>
        </div>
        <div className={gpunitsStyle.details}>
          <DistanceLogo color={color} distance={subtitle} />
        </div>
      </div>
    </div>
  );
};

const DistanceLogo = ({ distance = "20 KM", color = "red" }) => {
  return (
    <>
      <div className={gpunitsStyle.distance_logo_container}>
        <div className={gpunitsStyle.distance} style={{ color: color }}>
          {distance}
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="60"
          height="49"
          viewBox="0 0 60 49"
          fill="none"
        >
          <path
            d="M35.519 47.6175C39.8917 47.5003 44.1449 46.1699 47.7985 43.7763C51.4521 41.3827 54.3606 38.0213 56.196 34.0714C58.0314 30.1215 58.7204 25.7403 58.1854 21.4223C57.6505 17.1042 55.9127 13.0212 53.1683 9.63405C50.4239 6.24686 46.7821 3.69031 42.6539 2.2529C38.5257 0.815485 34.0754 0.554437 29.8052 1.49921C25.535 2.44398 21.6149 4.55695 18.4873 7.5997C15.3596 10.6424 13.149 14.4938 12.1049 18.7192L1.4821 25.2575L12.4593 31.1588C13.9675 36.0313 17.0382 40.278 21.2024 43.2501C25.3665 46.2222 30.3948 47.7561 35.519 47.6175Z"
            fill={color}
          />
        </svg>
      </div>
    </>
  );
};
// const GPUnits = ({ subtitle, title, color, backgroundColor, background }) => {
//   const divStyle = {
//     color: `${color}`,
//     backgroundColor: `${backgroundColor}`,
//     border: `${color} 1px solid `,
//     borderRadius: "4px",
//     paddingLeft: "20.38px",
//     // height: "80px",
//     display: "flex",
//     flexDirection: "row",
//     alignItems: "center",
//     // margin: "5px",
//     marginBottom: "24px",
//     gap: "53.31px",
//     justifyContent: "space-between",
//   };
//   const KilometerDiv = {
//     border: `${color} 1px solid `,
//     borderRight: "none",
//     height: "79px",
//     display: "flex",
//     alignItems: "center",
//     background: `${background}`,
//     borderRadius: "4px",
//     width: "200px",
//     justifyContent: "center",
//     position: "relative",
//   };
//   const LocationIocnDiv = {
//     position: "absolute",
//     left: "-20px",
//     backgroundColor: "white",
//     width: "36.14px",
//     height: "36.14px",
//     borderRadius: "25.1417px",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     border: `1px solid ${color}`,
//   };
//   return (
//     <div style={divStyle}>
//       <div className="location-title">{title}</div>
//       <div style={KilometerDiv}>
//         <div style={LocationIocnDiv}>
//           <EnvironmentOutlined />
//         </div>
//         {subtitle}
//       </div>
//     </div>
//   );
// };
