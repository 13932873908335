import "./index.css";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import { bannerImages } from "../../api/golednProperty";

const HomeSlider = () => {
  const [banners, setBanner] = useState([]);

  var settings = {
    dots: false,
    arrows: false,
    // speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1392,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          height: 1000,
        },
      },
      {
        breakpoint: 1060,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 745,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  useEffect(() => {
    bannerImages().then((res, rej) => {
      if (res.status === 200) {
        setBanner(res?.data?.banners);
      } else {
        console.log(rej, "this is rej");
      }
    });
  }, []);

  const scrollToElement = (id) => {
    var element = document.getElementById(id);
    element.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  return (
    <div
      style={{
        position: "relative",
        zIndex: 10,
      }}>
      <Slider {...settings}>
        {banners?.map((eachImage, index) => (
          <div key={eachImage?.id}>
            <img
              src={eachImage?.image}
              alt='Home Slider'
              style={{
                backgroundSize: "cover",
                width: "100%",
                cursor: "pointer",
                boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15",
              }}
              onClick={() => scrollToElement("contact")}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default HomeSlider;
