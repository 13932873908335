import "./index.css";
import { useEffect, useRef, useState } from "react";
import PageSection from "../global/PageSection";
import Slider from "react-slick";
import { APIBASE } from "../../apiCondig";
import { amenitiesData } from "../../api/golednProperty";

import bgImage from "../../assets/images/amenities_bg.png";
const Amenities = ({ }) => {
  const sliderRef = useRef();
  const [amenities, setAmenities] = useState([]);

  useEffect(() => {
    amenitiesData().then((res, rej) => {
      if (res.status === 200) {
        setAmenities(res?.data?.amenities);
      } else {
        console.log(rej, "this is rej");
      }
    });
  }, []);

  var settings = {
    dots: false,
    arrows: false,
    // speed: 500,
    slidesToShow: 6,
    slidesToScroll: 4,
    initialSlide: 0,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1392,
        settings: {
          arrows: false,
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 745,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 500,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  return (
    <PageSection
      title={"Amenities"}
      subTitle={"Go from browsing  to buying"}
      bgImage={bgImage}
      width={1220}
      titleTop={58}
      titleSubTitleSpace={16}
      titleBottom={57.51}
      style={{
        // marginTop: 190,
        // minHeight: 446,
        paddingBottom: 66.41,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left",
        position: "relative",
        zIndex: 8,
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
      }}>
      <div style={{ display: "flex" }} className='amenities-slides-container'>
        <div style={{ flex: 1, width: 50 }}>
          <div style={{}}>
            <Slider {...settings} ref={sliderRef}>
              {amenities.map((d, i) => {
                return (
                  <AmenitiesCard
                    imageUrl={d?.imageUrl}
                    title={d?.title}
                    subTitle={d?.subTitle}
                  />
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </PageSection>
  );
};

export function AmenitiesCard({ i, imageUrl, title, subTitle }) {
  return (
    <div key={"k" + i}>
      <div
        style={{
          // border: "1px solid red",
          display: "flex",
          justifyContent: "center",
        }}>
        <div
          style={{
            width: 182,
            height: 145.6,
            background: "#fff",
            display: "flex",
            flexDirection: "column",
            margin: "5px 0",
            // padding: 25,
            padding: 16.46,
            borderRadius: 25,
            textAlign: "center",
            alignItems: "center",
            justifyContent: "space-between",
            boxShadow:
              "0px -2px 2px rgba(208, 218, 244, 0.25), 0px 1px 3px #D0DAF4",
          }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 68.7,
              margin: "auto",
            }}>
            <img
              src={imageUrl}
              alt={title}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          {title && (
            <div style={{}} className='amenities-card-title'>
              {title}
            </div>
          )}
          {subTitle && (
            <div style={{}} className='amenities-card-sub-title'>
              {subTitle}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function loadAmenities(cb) {
  var myHeaders = new Headers();
  myHeaders.append(
    "Cookie",
    "sails.sid=s%3AVMjWRXtLoViz1nDUyIXJhuFsyJAlPltr.pXO57A0bsp%2FQMBh6x22Ze5rrVm2tGzixJwqUy1q2nAc"
  );

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  fetch(APIBASE + "/Amenities", requestOptions)
    .then((response) => response.text())

    .then((result) => cb(result))
    .catch((error) => console.log("error", error));
}
export default Amenities;
