import React from "react";
import PageSection from "../global/PageSection/index";
import useFulInfo from "./css/UseFulInfo.module.css";
import useFulInfoCard from "./css/UseFulInfoCard.module.css";
import image1 from "../../assets/images/UsefulInfo/icon1.svg";
import image2 from "../../assets/images/UsefulInfo/icon2.svg";
import image3 from "../../assets/images/UsefulInfo/icon3.svg";
import image4 from "../../assets/images/UsefulInfo/icon4.svg";
import rightArrow from "../../assets/images/UsefulInfo/right-arow.svg";
import { Link } from "react-router-dom";
export default function UsefulInformation() {
  return (
    <PageSection
      title={"Useful Information"}
      subTitle={"Additional information to solve your confusions"}
      // bgImage={backgroundImage}
      width={1347}
      titleTop={65}
      titleSubTitleSpace={22}
      titleBottom={61}
      style={{
        flex: 1,
        margin: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right bottom",
        backgroundColor: "#FBFFF5",
        paddingBottom: 6,
        backgroundSize: "100%",
      }}>
      <div className={useFulInfo.container}>
        <UseFulInfoCard
          image={image1}
          title='EMI Calculator'
          subTitle='Know how much you will have to pay every month on your loan'
        />
        <UseFulInfoCard
          image={image2}
          title='Rates & Trends'
          subTitle='Know all about Property Rates & Trends in your city'
        />
        <UseFulInfoCard
          image={image3}
          title='Legal Title Check'
          subTitle='Get property checked for clean title and no legal claim/bank attachment'
        />
        <UseFulInfoCard
          image={image4}
          title='Investment Hotspot'
          subTitle='Discover the top localities in your city for investment'
        />
      </div>
    </PageSection>
  );
}

export function UseFulInfoCard({ image, title, subTitle, link }) {
  const scrollToElement = (id) => {
    var element = document.getElementById(id);
    element.scrollIntoView({ block: "center", behavior: "smooth" });
  };
  return (
    <div className={useFulInfoCard.container}>
      <img src={image} alt={title}/>
      <h3 className={useFulInfoCard.title}>{title}</h3>
      <div className={useFulInfoCard.subTitle}>
        {`${subTitle.slice(1, 76)}${subTitle.length > 75 ? "..." : ""}`}
        </div>
      <Link
        to={link}
        className={useFulInfoCard.view_link}
        onClick={() => scrollToElement("contact")}>
        <div>View more</div>
        <img src={rightArrow} alt={title}/>
      </Link>
    </div>
  );
}
