import "./index.css";
const WhyUs = () => {
  const style = {
    flex: 1,
    backgroundSize: "50% 100%",
    margin: 0,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right bottom",
    backgroundColor: "#FBFFF5",
    "@media (max-width:778px)": {
      flex: 1,
      backgroundSize: "cover",
      margin: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right bottom ",
      backgroundColor: "#FBFFF5",
    },
  };
  return (
    <div className='why-us'>
      <div className='container'>
        <div className='page-section-header-container'>
          <div className='page-section-header-title'>{"Why Us?"}</div>
          <div className='page-section-header-subtitle'>
            {
              "At Golden Property, we understand the significance of our customer’s needs and strive to serve it."
            }
          </div>
        </div>
        <div className='card'>
          {[
            {
              index: "01",
              title: "Transparency",
              subTitle:
                "At Golden Property, we PROMISE that utmost transparency in our processes and offer best practices driven by our ethos.",
            },
            {
              index: "02",
              title: "Top Notch Services",
              subTitle:
                "At Golden Property, we are COMMITTED to provide superlative service to our customers to make it a hassle-free experience.",
            },
            {
              index: "03",
              title: "Satisfaction",
              subTitle:
                "At Golden Property, we offer the best market pricing and believe this is the ideal way of expressing our gratitude.",
            },
          ].map((d, i) => (
            <div key={d?.index} className='why-us-card' style={{}}>
              <div className='why-us-transparency-number'>{d?.index}</div>
              <div style={{ padding: 16 }}>
                <div className='why-us-transparency'>{d?.title}</div>
                <div className='why-us-transparency-sub-title'>
                  {d?.subTitle}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default WhyUs;
